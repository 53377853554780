import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import tw from "tailwind-styled-components";
import { InputGroup, Label } from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const TenantForm = ({
  setTenantData,
  setActiveStep,
  selectedBed,
  tenantData,
}) => {
  const occupationArray = ["Professional", "Student", "Others"];

  const IntialTenantValues = {
    name: tenantData?.name,
    mobile: tenantData?.mobile,
    gender: tenantData?.gender,
    occupation: tenantData?.occupation,
  };

  const TenantFormSchema = Yup.object().shape({
    name: Yup.string().required("Enter Name"),
    mobile: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter Correct Phone Number")
      .max(10, "Enter Correct Phone Number"),
    gender: Yup.string().required("Select Gender"),
    occupation: Yup.string().required("Select Occupation"),
  });

  const toastSelectBed = () =>
    toast.warn("Please Select Bed Id", {
      hideProgressBar: false,
    });
  const tenantFormik = useFormik({
    initialValues: IntialTenantValues,
    validationSchema: TenantFormSchema,
    onSubmit: (values) => {
      if (selectedBed) {
        setTenantData(values);
        setActiveStep(1);
      } else {
        toastSelectBed();
        return;
      }
    },
  });

  return (
    <form
      onSubmit={tenantFormik.handleSubmit}
      className="flex items-end flex-wrap gap-3 w-full"
      id="tenant-form"
    >
      <div className="w-full flex gap-5 mt-8 max-sm:flex-wrap">
        {/* TENANT NAME */}
        <InputGroup>
          <Label htmlFor="floor">Tenant Name</Label>
          <FieldWrapper>
            <Input
              type="text"
              name="name"
              id="tenantName"
              className="border border-gray-300 truncate"
              onChange={tenantFormik.handleChange}
              value={tenantFormik.values.name}
            />
            <div className="text-rose-500">
              {tenantFormik.touched.name && tenantFormik.errors.name}
            </div>
          </FieldWrapper>
        </InputGroup>

        {/* PHONE NUMBER */}
        <InputGroup>
          <Label htmlFor="floor">Phone No</Label>
          <FieldWrapper>
            <Input
              type="text"
              name="mobile"
              id="mobile"
              className="border border-gray-300 truncate"
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                const { value } = e.target;
                if (/^\d*$/.test(value)) {
                  tenantFormik.setFieldValue("mobile", value);
                }
              }}
              value={tenantFormik.values.mobile}
              maxLength={10}
            />
            <div className="text-rose-500">
              {tenantFormik.touched.mobile && tenantFormik.errors.mobile}
            </div>
          </FieldWrapper>
        </InputGroup>
      </div>
      <div className="w-full flex gap-5 max-sm:flex-wrap">
        {/* GENDER */}
        <InputGroup>
          <Label htmlFor="gender">Gender</Label>
          <FieldWrapper $select={true}>
            <Select
              className=" w-full pl-2"
              name="gender"
              onChange={tenantFormik.handleChange}
              value={tenantFormik.values.gender}
            >
              {/* <MenuItem value={0}>Select Gender</MenuItem> */}
              <MenuItem value={1}>Male</MenuItem>
              <MenuItem value={2}>Female</MenuItem>
            </Select>
            <div className="text-rose-500">
              {tenantFormik.touched.gender && tenantFormik.errors.gender}
            </div>
          </FieldWrapper>
        </InputGroup>
        {/* OCCUPATION */}
        <InputGroup>
          <Label htmlFor="occupation">Occupation</Label>
          <FieldWrapper $select={true}>
            <Select
              className=" w-full pl-2"
              name="occupation"
              onChange={tenantFormik.handleChange}
              value={tenantFormik.values.occupation}
            >
              {occupationArray.map((item, key) => (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <div className="text-rose-500">
              {tenantFormik.touched.occupation &&
                tenantFormik.errors.occupation}
            </div>
          </FieldWrapper>
        </InputGroup>
      </div>
      <div className="w-full flex gap-5"></div>
    </form>
  );
};

const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;
const FieldWrapper = tw.div`w-full`;
// const CombineInputGroup = tw.div`flex space-x-7`;

export default TenantForm;

import { React, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Login from "./Pages/Login";
import DashBoardNew from "./Pages/Admin/DashBoardNew";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RegisterPage from "./Pages/RegisterPage";
import ForgotPasswordPage from "./Pages/ForgotPassword";
import OtpPage from "./Pages/Otp";
import "./i18";
import { Remove_User } from "./Redux/actions";
import NotfoundPage from "./Components/NotfoundPage";
import Owners from "./Pages/Admin/Owners";
import Tenants from "./Pages/Admin/Tenants";
import Properties from "./Pages/Admin/Properties";
import AllProperties from "./Pages/Admin/AllProperties";
import Occupancy from "./Pages/Admin/Occupancy";
import PropComplaints from "./Pages/Admin/PropComplaints";
import TenantsComplaints from "./Pages/Admin/TenantsComplaints";
import LatestComplaints from "./Pages/Admin/LatestComplaints";
import Transactions from "./Pages/Admin/Transactions";
import Prospects from "./Pages/Admin/Prospects";
import RoomOptions from "./Pages/Admin/RoomOptions";
import Floors from "./Pages/Admin/Floors";
import OccupiedRooms from "./Pages/Admin/OccupiedRooms";
import Beds from "./Pages/Admin/Beds";
import AddTenant from "./Pages/Admin/AddTenant";
import ExtraCharges from "./Pages/Admin/ExtraCharges";
import SystemConfig from "./Pages/Admin/SystemConfig";
import OwnerExtraCharges from "./Pages/Admin/OwnerAddExtraCharges";
import Staff from "./Pages/Admin/Staff";
import AdminStaff from "./Pages/Admin/AdminStaff";
import { Expenses } from "./Pages/Admin/Expenses";
import { Colleges } from "./Pages/Admin/Colleges";
import { ExpensesType } from "./Pages/Admin/ExpensesType";
import Notifications from "./Pages/Admin/Notifications";
import AddBankPage from "./Pages/Admin/AddBankPage";
import Dues from "./Pages/Admin/Dues";
import Flats from "./Pages/Admin/Flats";
import Ledger from "./Pages/Admin/Ledger";
import RegistrationPage from "./Pages/Eqaro/Register";
import LoginPage from "./Pages/Eqaro/Login";
import EqaroUserListing from "./Pages/Eqaro/EqaroUserListing";
import PropertyForm from "./Pages/Eqaro/PropertyForm";

function App() {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const isSessionExpired = () => {
    if (user) {
      const expireAt = user?.expireAt;
      const currentTime = moment().valueOf();
      const isExpired = moment(currentTime).isAfter(expireAt);
      if (isExpired) {
        window.location.href = "/";
        dispatch(Remove_User());
      }
    }
  };
  useEffect(() => isSessionExpired(), []);
  if (!user)
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
        <Routes>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/get-otp" element={<OtpPage />} />
          <Route path="*" index element={<Login />} />
        </Routes>
      </>
    );
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotfoundPage />} />
        <Route path="/" element={<Owners />} />
        <Route path="/dashboard" element={<DashBoardNew />} />
        <Route path="/owners" element={<Owners />} />
        <Route path="/tenants" element={<Tenants />} />
        <Route path="/properties/:id" element={<Properties />} />
        <Route path="/occupancy/:id" element={<Occupancy />} />
        <Route path="/complaints/:id/:clientId" element={<PropComplaints />} />
        <Route path="/tenantscomplaints/:id" element={<TenantsComplaints />} />
        <Route path="/complaints" element={<LatestComplaints />} />
        <Route path="/transactions/:tenantId" element={<Transactions />} />
        {/* <Route path="/Room" element={<Room/>}/>  */}
        <Route path="prospects" element={<Prospects />} />
        <Route path="allproperties" element={<AllProperties />} />
        <Route path="roomoptions/:id" element={<RoomOptions />} />
        <Route path="floors/:id" element={<Floors />} />
        <Route path="flats/:id" element={<Flats />} />
        <Route path="beds/:roomId" element={<Beds />} />
        <Route
          path="occupiedroom/:propId/:roomId"
          element={<OccupiedRooms />}
        />
        <Route
          path="addtenant/:propId/:roomId/:clientId"
          element={<AddTenant />}
        />
        <Route
          path="extracharges/:clientId/:propId"
          element={<ExtraCharges />}
        />
        <Route path="/systemconfig" element={<SystemConfig />} />
        <Route path="/charges/:clientId" element={<OwnerExtraCharges />} />
        <Route path="/staff/:clientId" element={<Staff />} />
        <Route path="/allstaff" element={<AdminStaff />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/colleges" element={<Colleges />} />
        <Route path="/notifications/:clientId" element={<Notifications />} />
        <Route path="/dues/:id" element={<Dues />} />
        <Route path="/ledger/:id" element={<Ledger />} />
        <Route path="/expensestype" element={<ExpensesType />} />
        <Route path="/bank/:clientId" element={<AddBankPage />} />
        <Route path="/eqaroRegister" element={<RegistrationPage />} />
        <Route path="/eqaroLogin" element={<LoginPage />} />
        <Route path="/eqaroUserListing" element={<EqaroUserListing />} />
        <Route path="/eqaroPropertyForm" element={<PropertyForm />} />
      </Routes>
    </>
  );
}
export default App;

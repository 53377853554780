import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import { toast } from "react-toastify";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";

import Tables from "../../Components/AdminStaff/Tables";
import AddStaff from "../../Components/AdminStaff/AddStaff";
import EditStaff from "../../Components/Staff/EditStaff";

const AdminStaff = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("mobile");
  const [editUser, setEditUser] = useState(false);
  const [addStaff, setAddStaff] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [editStaff, setEditStaff] = useState(false);
  const [linkStaff, setLinkStaff] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    return await axios.post(`${Config.apiUrl}/getallstaffs`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getStaffSuccess = (res) => {
    // console.log("Res: ", res?.data?.data.map((item) => console.log(item.name)));
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getStaffError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getOwnersMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getStaffSuccess,
    onError: getStaffError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getOwnersMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    getOwnersMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  //------- Edit Single Entry -------
  const EditStaffFunction = async (values) => {
    const body = new FormData();
    body.append("id", values.id);
    body.append("name", values.name);
    body.append("mobile", values.mobile);
    body.append("status", values.status);
    return await axios.post(`${Config.apiUrl}/getallstaffs`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getOwnersMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editStaffMutate } = useMutation(
    EditStaffFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  const onAddStaffFunction = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    // formData.append("id", clientId);
    return await axios.post(`${Config.apiUrl}/addstaff`, formData, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getOwnersMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setAddStaff(false);
  };

  const onAddError = (data) => {
    setSelectedData("");
    setAddStaff(false);
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: isAddLoading, mutate: onMutateAdd } = useMutation(
    onAddStaffFunction,
    {
      onSuccess: onAddSuccess,
      onError: onAddError,
    }
  );

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {t("Staffs")}
                <Underline />
              </Title>
            </div>
            {/* <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setAddStaff(true)}
            >
              Add Staff
            </button> */}
          </div>

          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              ChangeHandler={ChangeHandler}
              filterValue={filterValue}
              filter={filter}
              user={user}
              t={t}
            />
          </div>
          {addStaff && (
            <AddStaff
              setAddStaff={setAddStaff}
              mutate={onMutateAdd}
              isLoading={isAddLoading}
            />
          )}
          {editStaff && (
            <EditStaff
              setEditStaff={setEditStaff}
              selectedData={selectedData}
              refetch={editStaffMutate}
              isLoading={editEntryLoading}
            />
          )}
          {(!isLoading || !isRefetching) && data && (
            <TableWrapper>
              <Tables
                ApiData={data?.data?.data}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setEditStaff={setEditStaff}
                setLinkStaff={setLinkStaff}
                refetch={refetch}
              />
              {(error || data?.data?.msg == "No data found") && !isLoading && (
                <NotFoundModel />
              )}
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  setFilterValue,
  filterValue,
  ChangeHandler,
  t,
}) => {
  const today = moment().format("YYYY-MM-DD");
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <div className="flex gap-2 items-end sm:text-base text-xs flex-wrap">
            <div className="flex items-end gap-2">
              <InputGroup className="max-w-[130px]">
                <Label htmlFor="startDate">{t("Start_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="startDate"
                    id="startDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup className="max-w-[130px]">
                <Label htmlFor="endDate">{t("End_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="endDate"
                    id="endDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>
            </div>
            <ApplyBtn disabled={InitialLoading} type="submit">
              {t("Date_filter")}
            </ApplyBtn>
          </div>

          <div className="flex gap-4 items-end">
            {filter !== "status" && filter !== "paystatus" ? (
              <InputGroup className="max-w-[200px]">
                <Label htmlFor="filterVal">{t("")}</Label>
                <Field
                  placeholder="Search..."
                  type="text"
                  name="filterVal"
                  id="filterVal"
                  inputMode={filter === "mobile" ? "numeric" : "text"}
                  maxLength={filter === "mobile" ? "10" : undefined}
                  pattern={filter === "mobile" ? "[0-9]*" : undefined}
                  class="ml-1"
                  value={filterValue}
                  onChange={(e) => {
                    if (filter === "mobile") {
                      const value = e.target.value;
                      // Restrict input to digits only, up to 10 characters
                      if (/^\d{0,10}$/.test(value)) {
                        ChangeHandler(e);
                      }
                    } else {
                      ChangeHandler(e);
                    }
                  }}
                  className="border border-slate-300 rounded-md px-4 focus:ring-1 focus:ring-primary-color focus:border-primary-color py-2"
                />
              </InputGroup>
            ) : filter === "status" ? (
              <InputGroup
                className="min-w-[120px]"
                style={{ flexBasis: "content" }}
              >
                <Label htmlFor="filter">{t("Select")}</Label>
                <Select
                  autoComplete="off"
                  className="px-2 bg-transparent text-sm border-none"
                  style={{ height: "40px" }}
                  value={filterValue}
                  onChange={ChangeHandler}
                >
                  <MenuItem value="" disabled>
                    {t("Select Status")}
                  </MenuItem>
                  <MenuItem value="3">{t("Active")}</MenuItem>
                  <MenuItem value="4">{t("Enrolled")}</MenuItem>
                </Select>
              </InputGroup>
            ) : null}

            <InputGroup
              className="min-w-[120px]"
              style={{ flexBasis: "content" }}
            >
              <Label htmlFor="filter">{t("Search By")}</Label>

              <Select
                autoComplete="off"
                className="w-24 px-2 bg-transparent text-sm border-none"
                style={{ height: "40px" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setFilterValue("");
                }}
              >
                <MenuItem value="mobile">Phone</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                {/* <MenuItem value="status">Status</MenuItem>
                {user.userType !== Config.userType.OPERATION && (
                  <MenuItem value="city">City</MenuItem>
                )} */}
              </Select>
            </InputGroup>
          </div>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-primary-color hover:bg-hover-color  py-2.5 text-sm`;
const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;
export default AdminStaff;

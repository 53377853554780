import React, { useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import { useSelector } from "react-redux";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const EditExtraChargs = ({
  selectedData,
  addExtraCharges,
  setAddExtraCharges,
  mutationData,
  loadingData,
}) => {
  const [charges, setCharges] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [repetation, setRepetation] = useState(
    selectedData?.repetitionType || "1"
  );

  const submitFunction = async () => {
    if (!charges) {
      setError("Please Enter a amount");
    } else if (charges.match(/^[0-9]+$/) === null) {
      setError("Please Enter only numbers");
    } else {
      if (charges) {
        setError(undefined);
        mutationData({ charges, repetation });
      }
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setAddExtraCharges}>
      {loadingData ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Edit Charges</Title>
          <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
            You may Edit Extra Charges for the {selectedData?.name}
            {/* <span className="text-primary-color">{selectedData?.name}</span> */}
          </p>
          <Wrapper>
            <InputGroup>
              <Label>Amount</Label>
              <FieldWrapper>
                <Input
                  type="text"
                  placeholder={`Previous Charge was ${selectedData.amount}₹`}
                  className="border rounded text-black"
                  value={charges}
                  // onChange={(e) => setCharges(e.target.value)}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^\d{0,10}$/.test(value)) {
                      console.log(value);
                      setCharges(value);
                    }
                  }}
                />
                {error && <p className="text-sm text-rose-600">{error}</p>}
              </FieldWrapper>
            </InputGroup>
            <InputGroup>
              <Label>Repetition</Label>
              <FieldWrapper>
                <Select
                  className="w-full pl-2"
                  value={repetation}
                  onChange={(e) => setRepetation(e.target.value)}
                >
                  <MenuItem
                    value="2"
                    disabled={
                      selectedData?.name === "Registration" ? true : false
                    }
                  >
                    Monthly
                  </MenuItem>
                  <MenuItem value="1">Once</MenuItem>
                </Select>
              </FieldWrapper>
            </InputGroup>
          </Wrapper>
          <BtnWrapper onClick={() => submitFunction()}>
            <SubmitBtn>Submit</SubmitBtn>
          </BtnWrapper>
        </Wrapper>
      )}
    </Model>
  );
};
const Wrapper = tw.div`px-1  space-y-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const FieldWrapper = tw.div`w-full`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default EditExtraChargs;

import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import moment from "moment";
import Config, { PaginationLimit } from "../../Config";
import { MdModeEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaMoneyCheck } from "react-icons/fa6";
import { IoDocumentTextOutline } from "react-icons/io5";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { IoMdMore } from "react-icons/io";
import { FiBook } from "react-icons/fi";
import NotFoundModel from "../../Components/NotFoundModel";

const Table = ({
  ApiData,
  setSelectedData,
  setEditUser,
  setViewDetail,
  setOpenKYCModel,
  userType,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [previousId, setPreviousId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActionMenu, setOpenActionMenu] = useState(null);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const viewKyc = (item) => {
    setOpenKYCModel(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  // const ActionBtns = (item) => {
  //   return (
  //     <ActionBtnWrapper>
  //         {userType != Config.userType.MARKETING && (
  //           <Btn title="Edit Tenants" onClick={() => edit(item)}>
  //             <MdModeEdit className="text-gray-700" />
  //           </Btn>
  //         )}
  //         <Btn title="View Details" onClick={() => viewDetail(item)}>
  //           <IoMdEye className="text-gray-700" />
  //         </Btn>
  //         <Btn title="Kyc" onClick={() => viewKyc(item)}>
  //           <HiClipboardDocumentList className="text-gray-700" />
  //         </Btn>
  //         {item.transactionCount > 0 && (
  //         <Btn  title="Transactions" onClick={() => viewTranstion(item)}>
  //           <FaMoneyCheck  className="text-gray-700" />
  //         </Btn>
  //         )}
  //     </ActionBtnWrapper>
  //   );
  // };

  const ActionBtns = (item) => {
    const openMenu = (id) => {
      setPreviousId(openActionMenu);
      setOpenActionMenu((prevId) => {
        return prevId === id ? null : id;
      });
    };

    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            {userType != Config.userType.MARKETING && (
              <MenuItem title="Edit Tenant" onClick={() => edit(item)}>
                <MdModeEdit className="text-gray-700 mr-2" /> Edit Tenant
              </MenuItem>
            )}
            <MenuItem title="View Detail" onClick={() => viewDetail(item)}>
              <IoMdEye className="text-gray-700 mr-2" /> View Detail
            </MenuItem>
            <MenuItem title="KYC" onClick={() => viewKyc(item)}>
              <HiClipboardDocumentList className="text-gray-700 mr-2" /> KYC
            </MenuItem>
            <MenuItem
              title="Dues"
              onClick={() =>
                navigate(`/dues/${item.id}`, {
                  state: { name: item.name },
                })
              }
            >
              <IoDocumentTextOutline className="text-gray-700 mr-2" /> Dues
            </MenuItem>
            <MenuItem
              title="Ledger"
              onClick={() =>
                navigate(`/ledger/${item.id}`, {
                  state: { name: item.name },
                })
              }
            >
              <FiBook className="text-gray-700 mr-2" /> Ledger
            </MenuItem>
            {item.transactionCount > 0 && (
              <MenuItem title="Transactions" onClick={() => viewTranstion(item)}>
                <FaMoneyCheck className="text-gray-700 mr-2" /> Transactions
              </MenuItem>

            )}
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: getName(item),
        getProperty: getProperty(item),
        getBedsFloors: getBedsFloors(item),
        moveInOutDate: moveInOutDate(item),
        status: combineStatusAndKyc(item),
        action: ActionBtns(item),
      }));
      setData(tempData);
    }
  }, [ApiData, openActionMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#menuOptions") &&
        !event.target.closest(".action-btn")
      ) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goToTenantsComplaints = (item) => {
    navigate(`/tenantscomplaints/${item.id}`);
  };
  const viewTranstion = (item) => {
    navigate(`/transactions/${item.id}`, { state: { name: item?.name } });
  };

  const getTenantGender = (gender) => {
    if (gender === 1) {
      return <span>Male</span>;
    } else {
      return <span>Female</span>;
    }
  };

  const getName = (item) => {
    return (
      <UserImageWrapper>
        <UserImage src={Images.Avatar} alt={`Avatar for ${item.name}`} />
        <div className="flex flex-col gap-1">
          <span className="text-zinc-900 font-medium">
            {item.name}{" "}
            <span className="text-xs bg-zinc-100 text-zinc-500 px-1 py-0.5 rounded-sm">
              {getTenantGender(item?.gender)}
            </span>
          </span>
          <span className="text-amber-500 text-xs">#{item.mobile}</span>
        </div>
      </UserImageWrapper>

      // <UserImageWrapper>
      //   <UserImage src={Images.Avatar} alt={`Avatar for ${item.name}`} />
      //   <div className="flex flex-col">
      //     <span className="font-medium text-zinc-900">{item.name} </span>
      //     <span className="text-xs text-blue-600">
      //       {getTenantGender(item?.gender)}
      //     </span>
      //     <span className="text-amber-500 text-xs">#{item.mobile}</span>
      //   </div>
      // </UserImageWrapper>
    );
  };

  // const getName_trash = (item) => {
  //   return (
  //     <div className="flex gap-2 items-center">
  //       <img
  //         src="/images/avatar.png"
  //         alt="Client"
  //         className="w-10 h-10 rounded-full overflow-hidden"
  //       />
  //       <div className="flex flex-col gap-[2px]">
  //         <p className="text-zinc-900 font-semibold w-[80px]">{item.name}</p>
  //         <p className="text-blue-600">{getTenantGender(item.gender)}</p>
  //         <p className="text-yellow-500 text-xs">#{item.mobile}</p>
  //       </div>
  //     </div>
  //   );
  // };

  const getStatus = (status) => {
    if (status === 1) {
      return <Unverified>Unverified</Unverified>;
    } else if (status === 2) {
      return <Vaccines>Vacant</Vaccines>;
    } else if (status === 3) {
      return <Linked>Linked</Linked>;
    } else if (status === 4) {
      return <Requested>Requested</Requested>;
    } else if (status === 5) {
      return <Occupied>Occupied</Occupied>;
    } else if (status === 6) {
      return <Moving>Moving Out</Moving>;
    } else if (status === 7) {
      return <Reserved>Reserved</Reserved>;
    }
  };

  const getKycStatus = (kycStatus) => {
    if (kycStatus === 1) {
      return <KycPending title="KYC Pending">P</KycPending>;
    } else if (kycStatus === 2) {
      return <IdUpload title="KYC ID Uploaded">IU</IdUpload>;
    } else if (kycStatus === 3) {
      return <Personal title="KYC Pinfo Submitted">PS</Personal>;
    } else if (kycStatus === 4) {
      return <Selfie title="KYC Selfie Uploaded">SU</Selfie>;
    } else if (kycStatus === 5) {
      return <RentAgreement title="KYC Rent Agreement">RA</RentAgreement>;
    } else if (kycStatus === 6) {
      return <Verification title="KYC Police Verification">PV</Verification>;
    } else {
      return <Unknown title="KYC Unknown status">US</Unknown>;
    }
  };

  const combineStatusAndKyc = (item) => {
    const statusElement = getStatus(item.status);
    const kycElement = getKycStatus(item.kycStatus);
    return (
      <div className="flex gap-1">
        <span>{statusElement}</span>
        <span>{kycElement}</span>
      </div>
    );
  };

  // const getProperty = (item) => {
  //   return (
  //     <div className="flex flex-col">
  //       {item.complaintCount > 0 ? (
  //         <span
  //           className="font-semibold cursor-pointer underline"
  //           onClick={() => goToTenantsComplaints(item)}
  //         >
  //           {item.propName}
  //         </span>
  //       ) : (
  //         <span className="font-medium text-zinc-800">{item.propName}</span>
  //       )}
  //       <span className="text-xs">
  //         Rent: {!item.rent && item.rent !== 0 ? "---" : `₹${item.rent}`}
  //       </span>
  //       <span className="text-xs">
  //         Security: {item.security === 0 ? "---" : `₹${item.security}`}
  //       </span>
  //     </div>
  //   );
  // };
  const getProperty = (item) => {
    return (
      <div className="flex gap-3 ">

        <div className="flex flex-col ">
          <span>{item.rent}</span>
          <span className="text-gray-400 font-medium text-xs">Total Rent</span>
        </div>
        <div className="flex flex-col">
          <span>{item.security}</span>
          <span className="text-gray-400 font-medium text-xs">
            Total Security
          </span>
        </div>
      </div>
    );
  };

  const getBedsFloors = (item) => {
    return (
      // <div className="flex flex-col">
      //   <span>Bed Id: {item.bedId != null ? item.bedId : "N/A"}</span>
      //   <span>Floor No: {item.floor != null ? item.floor : "N/A"}</span>
      // </div>
      <div className="flex gap-3 ">
        <div className="flex flex-col ">
          <span>{item.bedId != null ? item.bedId : "N/A"}</span>
          <span className="text-gray-400 font-medium text-xs">Bed Id</span>
        </div>
        <div className="flex flex-col">
          <span>{item.floor != null ? item.floor : "N/A"}</span>
          <span className="text-gray-400 font-medium text-xs">Floor No</span>
        </div>
      </div>
    );
  };

  const moveInOutDate = (item) => {
    return (
      // <div className="flex flex-col">
      //   <span>
      //     M.In :{" "}
      //     {item.moveInDate
      //       ? moment(item.moveInDate).format("DD-MM-YYYY")
      //       : "---"}
      //   </span>
      //   <span>
      //     M.Out :{" "}
      //     {item.moveOutDate
      //       ? moment(item.moveOutDate).format("DD-MM-YYYY")
      //       : "---"}
      //   </span>
      // </div>
      <div className="flex gap-3 ">
        <div className="flex flex-col ">
          <span>
            {item.moveInDate
              ? moment(item.moveInDate).format("DD-MM-YYYY")
              : "---"}
          </span>
          <span className="text-gray-400 font-medium text-xs">M.In Date</span>
        </div>
        <div className="flex flex-col">
          <span>
            {item.moveOutDate
              ? moment(item.moveOutDate).format("DD-MM-YYYY")
              : "---"}
          </span>
          <span className="text-gray-400 font-medium text-xs">M.Out Date</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Property",
          accessor: "getProperty",
        },
      ];
    }
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Rent/Security",
        accessor: "getProperty",
      },
      {
        Header: "Bed/Floor",
        accessor: "getBedsFloors",
      },
      {
        Header: "Move In/Out Date",
        accessor: "moveInOutDate",
      },
      {
        Header: "Status/KYC Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: currentPage, pageSize: PaginationLimit },
    },
    useSortBy,
    usePagination
  );

  // useEffect(() => {
  //   setPageSize(PaginationLimit);
  // }, []);
  useEffect(() => {
    setCurrentPage(pageIndex); // Update current page on state change
  }, [pageIndex]);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                          } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>Bed/Floor</strong>{" "}
                          {data[rowIndex].getBedsFloors}
                        </li>
                        <li>
                          <strong>Move In/Out Date</strong>{" "}
                          {data[rowIndex].moveInOutDate}
                        </li>
                        <li>
                          <strong>Bed/Floor</strong>{" "}
                          {data[rowIndex].getBedsFloors}
                        </li>
                        <li className="flex gap-2">
                          <strong>Status/KYC Status</strong>{" "}
                          {data[rowIndex].status}
                        </li>
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75"
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
      {ApiData?.length === 0 && <NotFoundModel />}
    </>
  );
};

const MenuOptions = tw.div`absolute bottom-10 right-0 py-4 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative`;
// const  ActionBtnWrapper = tw.div`
//   flex flex-wrap items-center w-[110px] mb-1 gap-y-1 gap-x-1.5 mt-20 md:mt-0
// `;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? "border-b" : "")}`;
const Tr = tw.tr`${(props) =>
  props.hasData
    ? "border-b"
    : ""} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
// const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const UserImageWrapper = tw.div` flex items-center gap-2`;
const UserImage = tw.img`w-10 h-10 rounded-full `;

const IdUpload = tw.div`bg-green-100 text-green-500 font-bold text-xs grid place-items-center rounded-lg h-7 w-7 cursor-pointer`;
const Personal = tw.div`bg-orange-100 text-orange-500 font-bold text-xs grid place-items-center rounded-lg h-7 w-7 cursor-pointer`;
const Selfie = tw.div`bg-lime-100 text-lime-500 font-bold text-xs grid place-items-center rounded-lg h-7 w-7 cursor-pointer`;
const RentAgreement = tw.div`bg-teal-100 text-teal-500 font-bold text-xs grid place-items-center rounded-lg h-7 w-7 cursor-pointer`;
const Verification = tw.div`bg-yellow-100 text-yellow-500 font-bold text-xs grid place-items-center rounded-lg h-7 w-7 cursor-pointer`;
const KycPending = tw.div`bg-blue-100 text-blue-500 font-bold text-xs grid place-items-center rounded-lg h-7 w-7 cursor-pointer`;
const Unknown = tw.div`bg-zinc-100 text-zinc-500 font-bold text-xs grid place-items-center rounded-lg h-7 w-7 cursor-pointer`;

const Unverified = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Vaccines = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Requested = tw.div`bg-lime-100 text-lime-700 text-xs border-lime-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Occupied = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Reserved = tw.div`bg-orange-100 text-orange-700 text-xs border-orange-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Linked = tw.div`bg-purple-100 text-purple-700 text-xs border-purple-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Moving = tw.div`bg-rose-100 text-rose-700 text-xs border-rose-300 border w-24 h-7 flex items-center justify-center rounded-full`;

export default Table;

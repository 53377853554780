import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoMdEye, IoMdMore } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import { RiFileEditFill } from "react-icons/ri";
import { usePagination, useSortBy, useTable } from "react-table";
import tw from "tailwind-styled-components";
import Config, { PaginationLimit } from "../../Config";
import Images from "../../Images";
import { NextBtn, PaginationWrapper, PrevBtn } from "../Styles/PageStyles";
const Table = ({
  ApiData,
  setSelectedData,
  setEditUser,
  setViewDetail,
  setViewDocument,
  userType,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [previousId, setPreviousId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActionMenu, setOpenActionMenu] = useState(null);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const viewDocument = (item) => {
    setViewDocument(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const ActionBtns = (item) => {
    const openMenu = (id) => {
      setPreviousId(openActionMenu);
      setOpenActionMenu((prevId) => {
        return prevId === id ? null : id;
      });
    };

    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            {userType != Config.userType.MARKETING && (
              <MenuItem title="Edit Properties" onClick={() => edit(item)}>
                <MdModeEdit className="text-gray-700 mr-2" /> Edit Properties
              </MenuItem>
            )}
            <MenuItem title="View Detail" onClick={() => viewDetail(item)}>
              <IoMdEye className="text-gray-700 mr-2" /> View Detail
            </MenuItem>
            <MenuItem title="View Document" onClick={() => viewDocument(item)}>
              <RiFileEditFill className="text-gray-700 mr-2" /> View Document
            </MenuItem>
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: getName(item),
        address: (
          <div className="max-w-[150px] text-zinc-500">{item.address}</div>
        ),
        getFineSecurity: getFineSecurity(item),
        numberOfBeds: getBedsTenants(item),
        createdUpdatedDate: createdUpdatedDate(item),
        status: getStatus(item.status),
        action: ActionBtns(item),
      }));
      setData(tempData);
    }
  }, [ApiData, openActionMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#menuOptions") &&
        !event.target.closest(".action-btn")
      ) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getTenantPreference = (tenantPreference) => {
    if (tenantPreference === 1) {
      return "Male";
    } else if (tenantPreference === 2) {
      return "Female";
    } else {
      return "Both";
    }
  };

  const getName = (item) => {
    return (
      <UserImageWrapper>
        <div className="flex flex-col">
          <span className="text-zinc-900 font-medium">
            {item.name}{" "}
            <span className="text-xs bg-zinc-100 text-zinc-500 px-1 py-0.5 rounded-sm">
              {getTenantPreference(item?.tenantPreference)}
            </span>
          </span>
          <span className="text-amber-500 text-xs">
            #{item.type === 1 ? "PG" : item.type === 2 ? "Flat" : ""}
          </span>
        </div>
      </UserImageWrapper>
    );
  };

  // const getFineSecurity = (item) => {
  //   return (
  //     <div className="flex flex-col">
  //       <span className="flex item-center gap-1 text-zinc-400">
  //         Fine:<span className="text-zinc-500 ">₹{item.fine}</span>
  //       </span>
  //       <span className="flex items-center text-zinc-400 gap-1">
  //         Security:<span className="text-zinc-500 ">₹{item.security}</span>
  //       </span>
  //     </div>
  //   );
  // };
  const getFineSecurity = (item) => {
    return (
      <div className="flex gap-3">
        <div className="flex flex-col">
          <span className="text-sm">₹{item.fine}</span>
          <span className="text-gray-400 font-medium text-xs">Total Fine</span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm">₹{item.security}</span>
          <span className="text-gray-400 font-medium text-xs">
            Total Security
          </span>
        </div>
      </div>
    );
  };

  // const getBedsTenants = (item) => {
  //   return (
  //     <div className="flex flex-col">
  //       <span className="flex item-center gap-1 text-zinc-400">
  //         Beds:<span className="text-zinc-500 ">{item.bedCount}</span>
  //       </span>
  //       <span className="flex items-center text-zinc-400 gap-1">
  //         Tenants:<span className="text-zinc-500 ">{item.tenantCount}</span>
  //       </span>
  //     </div>
  //   );
  // };
  const getBedsTenants = (item) => {
    return (
      <div className="flex gap-3">
        <div className="flex flex-col ">
          <span>{item.bedCount}</span>
          <span className="text-gray-400 font-medium text-xs">Total Beds</span>
        </div>
        <div className="flex flex-col">
          <span>{item.tenantCount}</span>
          <span className="text-gray-400 font-medium text-xs">
            Total Tenants
          </span>
        </div>
      </div>
    );
  };

  const createdUpdatedDate = (item) => {
    return (
      <div className="flex flex-col">
        <span>Created Date: {moment(item.createdAt).format("DD-MM-YYYY")}</span>
        <span>Updated Date: {moment(item.updatedAt).format("DD-MM-YYYY")}</span>
      </div>
    );
  };

  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;
    } else if (status === 2) {
      return <Completed>Completed</Completed>;
    } else if (status === 3) {
      return <Active>Active</Active>;
    } else if (status === 4) {
      return <Inactive>Inactive</Inactive>;
    } else {
      return "Invalid Status";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      return [
        {
          Header: "Property Details",
          accessor: "name",
        },
        {
          Header: "Fine/Security",
          accessor: "getFineSecurity",
        },
      ];
    }
    return [
      {
        Header: "Property Details",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Beds/Tenants",
        accessor: "numberOfBeds",
      },
      {
        Header: " Fine/Security",
        accessor: "getFineSecurity",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: currentPage, pageSize: PaginationLimit },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setCurrentPage(pageIndex);
  }, [pageIndex]);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={column.style}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${
                          column.isSortedDesc ? "-rotate-90" : "rotate-90"
                        } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 1}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        style={cell.column.style}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>Address:</strong> {data[rowIndex].address}
                        </li>
                        <li>
                          <strong>Number Of Beds</strong>{" "}
                          {data[rowIndex].numberOfBeds}
                        </li>
                        <li className="flex gap-2">
                          <strong>Status:</strong> {data[rowIndex].status}
                        </li>
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75"
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const MenuOptions = tw.div`absolute bottom-10 right-0 py-4 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative`;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? "border-b" : "")}`;
const UserImageWrapper = tw.div` flex items-center gap-2`;
const Tr = tw.tr`${(props) =>
  props.hasData
    ? "border-b"
    : ""} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const Active = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Completed = tw.div`bg-lime-100 text-lime-700 text-xs border-lime-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Inactive = tw.div`bg-red-100 text-red-700 text-xs border-red-300 border w-24 h-7 flex items-center justify-center rounded-full`;
export default Table;

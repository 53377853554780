import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Ledger/Table";
import Navbar from "../../Components/Navbar";
import { toast } from "react-toastify";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ViewDetailModel from "../../Components/Ledger/ViewDetail";
import EditLedgerModel from "../../Components/Ledger/EditLedgerModel";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";

const Ledger = () => {
  const location = useLocation();
  const user = useSelector((state) => state.UserReducer.user);
  const { id: propId } = useParams();
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [viewDetail, setViewDetail] = useState(false);
  const [filter, setFilter] = useState("name");
  const [editLedger, setEditLedger] = useState(false);
  const [addLedger, setAddLedger] = useState(false);
  const [apiData, setApiData] = useState();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("tenantId", propId);
    return await axios.post(`${Config.apiUrl}/gettenantledgers`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getLedgerSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
    setApiData(res.data);
  };

  const getLedgerError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getLedgerMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getLedgerSuccess,
    onError: getLedgerError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getLedgerMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);
  console.log(data);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    getLedgerMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  //------- Edit Single Entry -------
  //   const EditLedgerFunction = async (values) => {
  //     const body = new FormData();
  //     body.append("tenantId", propId);
  //     body.append("id", values.id);
  //     body.append("amount", values.amount);
  //     return await axios.post(`${Config.apiUrl}/edittenantledger`, body, {
  //       headers: {
  //         authorization: "Bearer" + " " + user.token,
  //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //       },
  //     });
  //   };

  //   const onEditEntrySuccess = (data) => {
  //     if (data?.data?.status == "OK") {
  //       toast.success(data?.data?.msg || "Success");
  //       setApiData(data.data);
  //     } else {
  //       toast.error(data?.data?.msg || "An Error Occured");
  //     }
  //     setSelectedData("");
  //     setEditLedger(false);
  //   };

  //   const onEditEntryError = (data) => {
  //     setEditLedger(false);
  //     setSelectedData("");
  //     toast.error(data?.data?.msg || "An Error Occured");
  //   };

  //   const { isLoading: editEntryLoading, mutate: editLedgerMutate } = useMutation(
  //     EditLedgerFunction,
  //     {
  //       onSuccess: onEditEntrySuccess,
  //       onError: onEditEntryError,
  //     }
  //   );

  //Add Ledger Function
  // const AddLedgerFunction = async (values) => {
  //   const formData = new FormData();
  //   Object.keys(values).forEach((key) => formData.append(key, values[key]));
  //   // formData.append("id", clientId);
  //   return await axios.post(`${Config.apiUrl}/addtenantledger`, formData, {
  //     headers: {
  //       authorization: `Bearer ${user.token}`,
  //       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //     },
  //   });
  // };

  // const onAddLedgerSuccess = (data) => {
  //   if (data?.data?.status === "OK") {
  //     toast.success(data?.data?.msg || "Success");
  //     console.log(data?.data);
  //   } else {
  //     toast.error(data?.data?.msg || "An Error Occured");
  //   }
  //   setSelectedData("");
  //   setAddLedger(false);
  // };

  // const onAddLedgerError = (data) => {
  //   setAddLedger(false);
  //   setSelectedData("");
  //   toast.error(data?.response?.data?.msg || "An Error Occured");
  // };

  // const { isLoading: addLedgerLoading, mutate: addLedgerMutate } = useMutation(
  //   AddLedgerFunction,
  //   {
  //     onSuccess: onAddLedgerSuccess,
  //     onError: onAddLedgerError,
  //   }
  // );

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Ledger"}
                <span>/{location?.state?.name}</span>
                <Underline />
              </Title>
            </div>
            {/* <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setAddLedger(true)}
            >
              Add Ledger
            </button> */}
          </div>

          {/* <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              ChangeHandler={ChangeHandler}
              filterValue={filterValue}
              filter={filter}
              t={t}
            />
          </div> */}

          {/* {editLedger && (
            <EditLedgerModel
              editLedgerMutate={editLedgerMutate}
              editEntryLoading={editEntryLoading}
              setEditLedger={setEditLedger}
              data={data?.data}
              selectedData={selectedData}
            />
          )} */}

          {/* {addLedger && (
            <AddLedger
              addLedgerMutate={addLedgerMutate}
              addLedgerLoading={addLedgerLoading}
              setAddLedger={setAddLedger}
              data={data?.data}
              selectedData={selectedData}
            />
          )} */}
          {viewDetail && (
            <ViewDetailModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : apiData?.data}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditLedger={setEditLedger}
                setViewDetail={setViewDetail}
                userType={user.userType}
              />
              {(error || apiData?.msg == "No data found") && !isLoading && (
                <NotFoundModel />
              )}
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
        </BoxContainer>
      </Bg>
    </>
  );
};

// const Filters = ({
//   initialValues,
//   submitHandler,
//   InitialLoading,
//   filter,
//   setFilter,
//   setFilterValue,
//   filterValue,
//   ChangeHandler,
//   t,
// }) => {
//   const today = moment().format("YYYY-MM-DD");
//   return (
//     <Formik initialValues={initialValues} onSubmit={submitHandler}>
//       <Form className="w-full">
//         <FilterContainer>
//           <div className="flex gap-2 items-end sm:text-base text-xs flex-wrap">
//             <div className="flex items-end gap-2">
//               <InputGroup className="max-w-[130px]">
//                 <Label htmlFor="startDate">{t("Start_Date")}:</Label>
//                 <FieldWrapper className="h-10">
//                   <img
//                     src={Images.Calender}
//                     alt="Calender icon"
//                     className="w-3.5"
//                   />
//                   <Field
//                     type="date"
//                     name="startDate"
//                     id="startDate"
//                     class="ml-1"
//                     max={today}
//                   />
//                 </FieldWrapper>
//               </InputGroup>

//               <InputGroup className="max-w-[130px]">
//                 <Label htmlFor="endDate">{t("End_Date")}:</Label>
//                 <FieldWrapper className="h-10">
//                   <img
//                     src={Images.Calender}
//                     alt="Calender icon"
//                     className="w-3.5"
//                   />
//                   <Field
//                     type="date"
//                     name="endDate"
//                     id="endDate"
//                     class="ml-1"
//                     max={today}
//                   />
//                 </FieldWrapper>
//               </InputGroup>
//             </div>
//             <ApplyBtn disabled={InitialLoading} type="submit">
//               {t("Date_filter")}
//             </ApplyBtn>
//           </div>

//           <div className="flex gap-4 items-end">
//             {filter !== "status" && filter !== "paystatus" ? (
//               <InputGroup className="max-w-[200px]">
//                 <Label htmlFor="filterVal">{t("")}</Label>
//                 <Field
//                   placeholder="Search..."
//                   type="text"
//                   name="filterVal"
//                   id="filterVal"
//                   class="ml-1"
//                   value={filterValue}
//                   onChange={ChangeHandler}
//                   className="border border-slate-300 rounded-md px-4 focus:ring-1 focus:ring-primary-color focus:border-primary-color py-2"
//                 />
//               </InputGroup>
//             ) : filter === "status" ? (
//               <InputGroup
//                 className="min-w-[120px]"
//                 style={{ flexBasis: "content" }}
//               >
//                 <Label htmlFor="filter">{t("Select")}</Label>
//                 <Select
//                   autoComplete="off"
//                   className="px-2 bg-transparent text-sm border-none"
//                   style={{ height: "40px" }}
//                   value={filterValue}
//                   onChange={ChangeHandler}
//                 >
//                   <MenuItem value="" disabled>
//                     {t("Select Status")}
//                   </MenuItem>
//                   <MenuItem value="1">{t("Pending")}</MenuItem>
//                   <MenuItem value="2">{t("Requested")}</MenuItem>
//                   <MenuItem value="3">{t("OCCUPIED")}</MenuItem>
//                   <MenuItem value="4">{t("MOVING OUT")}</MenuItem>
//                   <MenuItem value="5">{t("RESERVED")}</MenuItem>
//                   {/* <MenuItem value="6">{t("Moving Out")}</MenuItem> */}
//                 </Select>
//               </InputGroup>
//             ) : null}

//             <InputGroup
//               className="min-w-[120px]"
//               style={{ flexBasis: "content" }}
//             >
//               <Label htmlFor="filter">{t("Search By")}</Label>

//               <Select
//                 autoComplete="off"
//                 className="w-24 px-2 bg-transparent text-sm border-none"
//                 style={{ height: "40px" }}
//                 value={filter}
//                 onChange={(e) => {
//                   setFilter(e.target.value);
//                   setFilterValue("");
//                 }}
//               >
//                 <MenuItem value="name">Name</MenuItem>
//                 <MenuItem value="mobile">Phone</MenuItem>
//                 <MenuItem value="status">Status</MenuItem>
//                 <MenuItem value="property">Property</MenuItem>
//               </Select>
//             </InputGroup>
//           </div>
//         </FilterContainer>
//       </Form>
//     </Formik>
//   );
// };

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-primary-color hover:bg-hover-color  py-2.5 text-sm`;

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;

export default Ledger;

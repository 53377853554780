import React from "react";
import tw from "tailwind-styled-components";
import { useFormik } from "formik";
import Loading from "../Loading";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import moment from "moment";
import Images from "../../Images";
import * as Yup from "yup";

const EditOccupancysModel = ({
  editTenantsMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const today = moment().format("YYYY-MM-DD");
  const formik = useFormik({
    initialValues: {
      id: selectedData?.id,
      tenantId: selectedData?.tenantId,
      name: selectedData?.name || "",
      email: selectedData?.email || "",
      mobile: selectedData?.mobile || "",
      status: selectedData?.status || "",
      // moveOutDate: selectedData?.moveOutDate || "",
      gender: selectedData?.gender || "",
      occupation: selectedData?.occupation || "",
      kycStatus: selectedData?.kycStatus || "",
      moveInDate: moment(selectedData?.moveInDate).format("YYYY-MM-DD"),
      agreementStartDate: moment(selectedData?.agreementStartDate).format(
        "YYYY-MM-DD"
      ),
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string(),
      mobile: Yup.number().required("Phone number is required"),
      status: Yup.string().required("Select status"),
      occupation: Yup.string().required("Select occupation"),
      gender: Yup.string().required("Select gender"),
      kycStatus: Yup.string().required("Select kyc status"),
      moveInDate: Yup.date().required("Move in date is required"),
    }),
    onSubmit: (values) => {
      // if (formik.values.status !== "5") {
      //   delete values.moveOutDate;
      // }
      const rentalCycle = moment(values.moveInDate).format("DD");
      const updatedValues = {
        ...values,
        agreementStartDate: values.moveInDate,
        rentalCycle: rentalCycle,
      };
      editTenantsMutate(updatedValues);
    },
    enableReinitialize: true,
  });

  // const SubmitHandler = (values) => {
  //   if (!values.name) {
  //     toast.error("Please enter your Name");
  //     return;
  //   } else if (!values.occupation) {
  //     toast.error("Please enter your Occupation");
  //     return;
  //   } else if (!values.gender) {
  //     toast.error("Please enter your Gender");
  //     return;
  //   }
  //   console.log(values);
  //   editTenantsMutate(values);
  // };


  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Tenants</Title>

      {editEntryLoading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Wrapper>
            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    autoComplete="off"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.name}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    autoComplete="off"
                  />
                  {/* {formik.touched.email && formik.errors.email && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.email}
                    </p>
                  )} */}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>
            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="mobile">Phone</Label>
                <FieldWrapper className="bg-gray-100">
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    disabled
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    required
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.mobile}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="status">Status</Label>
                <FieldWrapper $select={true}>
                  <Select
                    name="status"
                    id="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    className="w-full pl-2"
                  >
                    <MenuItem value="1">Unverified</MenuItem>
                    <MenuItem value="2">Vacant</MenuItem>
                    <MenuItem value="3">Linked</MenuItem>
                    <MenuItem value="4">Requested</MenuItem>
                    <MenuItem value="5">Occupied</MenuItem>
                    <MenuItem value="6">Moving Out</MenuItem>
                    <MenuItem value="7">Reserved</MenuItem>
                  </Select>
                  {formik.touched.status && formik.errors.status && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.status}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>

            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="occupation">Occupation</Label>
                <FieldWrapper $select={true}>
                  <Select
                    name="occupation"
                    id="occupation"
                    value={formik.values.occupation}
                    onChange={formik.handleChange}
                    className="w-full pl-2"
                  >
                    <MenuItem value="Student">Student</MenuItem>
                    <MenuItem value="Professional">Professional</MenuItem>
                  </Select>
                  {formik.touched.occupation && formik.errors.occupation && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.occupation}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="gender">Gender</Label>
                <FieldWrapper $select={true}>
                  <Select
                    name="gender"
                    id="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    className="w-full pl-2"
                  >
                    <MenuItem value="1">Male</MenuItem>
                    <MenuItem value="2">Female</MenuItem>
                  </Select>
                  {formik.touched.gender && formik.errors.gender && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.gender}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>
            <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="kycStatus">KYC Status</Label>
                <FieldWrapper $select={true}>
                  <Select
                    name="kycStatus"
                    id="kycStatus"
                    value={formik.values.kycStatus}
                    onChange={formik.handleChange}
                    className="w-full pl-2"
                  >
                    <MenuItem value="1">Pending</MenuItem>
                    <MenuItem value="2">ID Upload</MenuItem>
                    <MenuItem value="3">Personal Info</MenuItem>
                    <MenuItem value="4">Selfie Uploaded</MenuItem>
                    <MenuItem value="5">Rent Agreement Done</MenuItem>
                    <MenuItem value="6">Police Verification Done</MenuItem>
                  </Select>
                  {formik.touched.kycStatus && formik.errors.kycStatus && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.kycStatus}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="moveInDate">Move In Date</Label>
                <FieldWrapper>
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="absolute w-4 left-2 top-3"
                  />
                  <Input
                    type="date"
                    name="moveInDate"
                    id="moveInDate"
                    className="pl-8"
                    value={formik.values.moveInDate}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.moveInDate && formik.errors.moveInDate && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.moveInDate}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
            </CombineInputGroup>
            {/* {formik.values.status === "5" && (
              <InputGroup>
                <Label htmlFor="moveout date">Moveout Date</Label>
                <FieldWrapper className="bg-gray-100">
                  <Input
                    type="date"
                    name="moveOutDate"
                    id="moveOutDate"
                    value={formik.values.moveOutDate}
                    onChange={formik.handleChange}
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>
            )} */}
          </Wrapper>
          <BtnWrapper>
            <SubmitBtn type="submit">Update</SubmitBtn>
          </BtnWrapper>
        </form>
      )}
    </Model>
  );
};

const Wrapper = tw.div`grid gap-6 my-6 px-1`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const FieldWrapper = tw.div`w-full relative`;
const CombineInputGroup = tw.div`flex space-x-7`;
const Input = tw.input`truncate border border-gray-300 border-m rounded-md pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default EditOccupancysModel;

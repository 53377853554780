import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Dues/Table";
import Navbar from "../../Components/Navbar";
import { toast } from "react-toastify";
import {
  Bg,
  BoxContainer,
  Underline,
  Button,
} from "../../Components/Styles/PageStyles";
import NotFoundModel from "../../Components/NotFoundModel";
import ViewDetailModel from "../../Components/Dues/ViewDetail";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditDuesModel from "../../Components/Dues/EditDuesModel";
import AddDues from "../../Components/Dues/AddDues";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { MdAdd } from "react-icons/md";

const Dues = () => {
  const location = useLocation();

  const user = useSelector((state) => state.UserReducer.user);
  const { id } = useParams();
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editDues, setEditDues] = useState(false);
  const [addDues, setAddDues] = useState(false);
  const [apiData, setApiData] = useState();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("tenantId", id);
    return await axios.post(`${Config.apiUrl}/gettenantdues`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getDuesSuccess = (res) => {
    if (res?.data?.data?.status == "NOK")
      error = true;
    else
      setApiData(res.data);
  };

  const getDuesError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getDuesMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getDuesSuccess,
    onError: getDuesError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getDuesMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);
  // console.log(data);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    getDuesMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  //------- Edit Single Entry -------
  const EditDuesFunction = async (values) => {
    const body = new FormData();
    body.append("id", values.id);
    body.append("balance", values.balance);
    body.append("clientId", values.clientId);

    return await axios.post(`${Config.apiUrl}/edittenantdues`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      setApiData(data.data);
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditDues(false);
  };

  const onEditEntryError = (data) => {
    setEditDues(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editDuesMutate } = useMutation(
    EditDuesFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  //Add Dues Function
  const AddDuesFunction = async (values) => {
    const formData = new FormData();

    formData.append("clientId", apiData?.data[0]?.clientId);
    formData.append("dueType", values.dueType);
    formData.append("tenantId", apiData?.data[0]?.tenantId);
    formData.append("amount", values.amount);
    return await axios.post(`${Config.apiUrl}/addtenantdues`, formData, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddDuesSuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Success");
      setApiData(data.data);
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setAddDues(false);
  };

  const onAddDuesError = (data) => {
    setAddDues(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: addDuesLoading, mutate: addDuesMutate } = useMutation(
    AddDuesFunction,
    {
      onSuccess: onAddDuesSuccess,
      onError: onAddDuesError,
    }
  );

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Dues"}
                <span>/{location?.state?.name}</span>
                <Underline />
              </Title>
            </div>
            {/* <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setAddDues(true)}
            >
              Add Dues
            </button> */}
            <UploadWrapper>
              <Button type="button" onClick={() => setAddDues(true)}>
                <MdAdd className="text-white w-5 h-5" />{" "}
                <p className="text-sm">{"Add Dues"}</p>
              </Button>
            </UploadWrapper>
          </div>

          {editDues && (
            <EditDuesModel
              editDuesMutate={editDuesMutate}
              editEntryLoading={editEntryLoading}
              setEditDues={setEditDues}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {viewDetail && (
            <ViewDetailModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}

          {addDues && (
            <AddDues
              addDuesMutate={addDuesMutate}
              addDuesLoading={addDuesLoading}
              setAddDues={setAddDues}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : apiData?.data}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setViewDetail={setViewDetail}
                setEditDues={setEditDues}
                userType={user.userType}
              />
              {(error || apiData?.msg == "No data found") && !isLoading && (
                <NotFoundModel />
              )}
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const UploadWrapper = tw.div`w-full flex justify-end space-x-4 items-center `;

export default Dues;

import React from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import { SubmitBtn } from "../Styles/InputStyles";

const EditFlats = ({
  editOwnerMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const initialValues = {
    room: selectedData?.room || "",
    repeat: false,
  };

  const validationSchema = Yup.object().shape({
    room: Yup.string()
      .matches(/^\d{1}$/, "Only numbers are allowed")
      .test("range", "Value must be between 1 and 9", (value) => {
        return value === "" || (Number(value) >= 1 && Number(value) <= 9);
      }),
    repeat: Yup.boolean(),
  });

  const handleSubmit = (values) => {
    const formattedValues = { ...values };
    editOwnerMutate(formattedValues);
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Wrapper>
        <Title>Edit Rooms</Title>
        <hr className="text-gray-600" />

        {editEntryLoading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ errors, touched }) => (
              <Form>
                <Wrapper>
                  <InputGroup>
                    <Label htmlFor="room">Number of Rooms</Label>
                    <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
                      Set number of rooms to increase or decrease on the flat.
                    </p>
                    <FieldWrapper>
                      <Field
                        type="text"
                        name="room"
                        placeholder="Enter number of rooms"
                        className="truncate border border-gray-300  rounded pl-2 "
                      />
                      {touched.room && errors.room && (
                        <p className="text-rose-600 text-sm">{errors.room}</p>
                      )}
                    </FieldWrapper>
                  </InputGroup>

                  <hr className="mt-5 text-gray-600 mb-3" />

                  <InputGroup>
                    <FieldWrapper>
                      <div className="flex items-center justify-between">
                        <Label htmlFor="repeat">Replicate</Label>
                        <label className="inline-flex items-center cursor-pointer">
                          <Field
                            type="checkbox"
                            name="repeat"
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
                        </label>
                      </div>
                      <p className="text-xs md:text-sm lg:text-base text-gray-600 mt-1">
                        Set if you want to increase or decrease room on every flat.
                      </p>
                    </FieldWrapper>
                  </InputGroup>
                </Wrapper>

                <BtnWrapper>
                  <SubmitBtn type="submit">Update</SubmitBtn>
                </BtnWrapper>
              </Form>
            )}
          </Formik>
        )}
      </Wrapper>
    </Model>
  );
};

const Wrapper = tw.div`px-1 space-y-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const InputGroup = tw.div`w-full space-y-2`;
const Label = tw.label`text-sm text-gray-600 font-medium`;
const FieldWrapper = tw.div`w-full`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

export default EditFlats;

import React from "react";
import tw from "tailwind-styled-components";
import { useFormik } from "formik";
import Loading from "../Loading";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import Model from "../Model";
import * as Yup from "yup";
import { toast } from "react-toastify";

const EditLedgerModel = ({
  editLedgerMutate,
  editEntryLoading,
  setEditLedger,
  selectedData,
}) => {
  const SubmitHandler = (values) => {
    if (!values.amount) {
      toast.error("Please enter Ledger amount");
      return;
    }
    // console.log(values);
    editLedgerMutate(values);
  };

  const formik = useFormik({
    initialValues: {
      id: selectedData?.id || "",
      amount: selectedData?.amount || "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required("Amount is required"),
    }),
    onSubmit: SubmitHandler,
    enableReinitialize: true,
  });

  console.log(selectedData);

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditLedger}>
      {editEntryLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Edit Ledger</Title>
          <form onSubmit={formik.handleSubmit}>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="amount">Amount</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="amount"
                    id="amount"
                    autoComplete="off"
                    className="truncate"
                    required
                    value={formik.values.amount}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      if (value.length <= 7) {
                        // Limits the length to 7 digits
                        formik.setFieldValue("amount", value);
                      }
                    }}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.amount}
                    </p>
                  )}
                </FieldWrapper>
              </InputGroup>
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default EditLedgerModel;

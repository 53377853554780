import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;
    } else if (status === 2) {
      return <Resolve>SUCCESS</Resolve>;
    } else {
      return <Inprogress>FAILED</Inprogress>;
    }
  };

  const getType = (type) => {
    if (type === 1) {
      return "Tenant Paid";
    } else if (type === 2) {
      return "Owner Paid";
    } else {
      return "Security Adjusted";
    }
  };
  const getTransactionFor = (transactionFor) => {
    if (transactionFor === 1) {
      return "Rent";
    } else if (transactionFor === 2) {
      return "Security";
    } else if (transactionFor === 3) {
      return "Extra Charge";
    } else if (transactionFor === 4) {
      return "Other";
    } else {
      return "Dues Settlement";
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title>
          <MdViewHeadline className="mt-1" />
          View Details
        </Title>
        <SingleItem
          name={"Tenant Name"}
          value={selectedData?.tenantName || "N/A"}
        />
        <SingleItem
          name={"Client Name"}
          value={selectedData?.clientName || "N/A"}
        />
        <SingleItem
          name={"Property Name"}
          value={selectedData?.propName || "N/A"}
        />
        <SingleItem
          name="Status"
          value={getStatus(selectedData?.status) || "N/A"}
        />
        {/* <SingleItem name={"Floor"} value={selectedData?.floor || "N/A"} />   */}
        <SingleItem name={"Amount"} value={selectedData?.amount || "N/A"} />
        <SingleItem name={"Title"} value={selectedData?.name} />
        <SingleItem
          name={"Transaction Type"}
          value={getType(selectedData?.type) || "N/A"}
        />
        <SingleItem
          name={"Transaction For"}
          value={getTransactionFor(selectedData?.transactionFor) || "N/A"}
        />
        <SingleItem name={"Mode"} value={selectedData?.mode || "N/A"} />
        <SingleItem name={"Due Date"} value={selectedData?.dueDate || "N/A"} />
        <SingleItem
          name={"Created At"}
          value={selectedData?.createdAt || "N/A"}
        />
        <SingleItem
          name={"Updated At"}
          value={selectedData?.updatedAt || "N/A"}
        />
        <SingleItem
          name={"Receipt"}
          value={
            selectedData?.receipt ? (
              <div className="flex items-center">
                <svg
                  className="h-4 w-4 text-primary-color"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  />
                </svg>
                <a
                  href={selectedData.receipt}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline text-primary-color ml-1 text-sm"
                >
                  Receipt
                </a>
              </div>
            ) : (
              "N/A"
            )
          }
        />
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    <Value>
      {name === "Amount" && <span className="mr-1">₹</span>}
      {value}
    </Value>
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
const Inprogress = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const Resolve = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
export default ViewDetailModel;

import React from "react";
import tw from "tailwind-styled-components";
import { useFormik } from "formik";
import Loading from "../Loading";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import * as Yup from "yup";

const EditNotificationModel = ({
  editNotificationMutate,
  editEntryLoading,
  setEditNotification,
  selectedData,
}) => {
  const SubmitHandler = (values) => {
    editNotificationMutate(values);
  };

  const formik = useFormik({
    initialValues: {
      notificationType: selectedData?.notificationType || "",
      isEnabled: selectedData?.isEnabled || 0,
      isSelfBranding: selectedData?.isSelfBranding || 0,
    },
    validationSchema: Yup.object().shape({
      isEnabled: Yup.number().required("Select an option"),
      isSelfBranding: Yup.number().required("Select an option"),
    }),
    onSubmit: SubmitHandler,
    enableReinitialize: true,
  });

  const notificationLabels = {
    1: "WhatsApp",
    2: "SMS",
  };

  const notificationTitle =
    notificationLabels[selectedData?.notificationType] || "Notification";

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditNotification}>
      {editEntryLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Edit {notificationTitle}</Title>
          <form onSubmit={formik.handleSubmit}>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="isEnabled">Notification Enabled</Label>
                  <FieldWrapper $select={true}>
                    <Select
                      name="isEnabled"
                      id="isEnabled"
                      value={formik.values.isEnabled}
                      onChange={formik.handleChange}
                      className="w-full pl-2"
                      displayEmpty
                    >
                      <MenuItem value="0">No</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                    </Select>
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="isSelfBranding">Self Branded</Label>
                  <FieldWrapper $select={true}>
                    <Select
                      name="isSelfBranding"
                      id="isSelfBranding"
                      value={formik.values.isSelfBranding}
                      onChange={formik.handleChange}
                      className="w-full pl-2"
                      displayEmpty
                      disabled={formik.values.isEnabled == 0} // Disabled when IsEnabled is selected No
                    >
                      <MenuItem value="0">No</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                    </Select>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const CombineInputGroup = tw.div`flex space-x-7`;

export default EditNotificationModel;

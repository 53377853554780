import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Notifications/Tables";
import Navbar from "../../Components/Navbar";
import { toast } from "react-toastify";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditNotificationModel from "../../Components/Notifications/EditNotificationModel";
import { useLocation, useParams } from "react-router-dom";

const Notifications = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState("");
  const { clientId } = useParams();
  const [notificationType, setNotificationType] = useState("");
  const [isEnabled, setIsEnabled] = useState("");
  const [isSelfBranding, setIsSelfBranding] = useState("");
  const [editNotification, setEditNotification] = useState(false);
  const [apiData, setApiData] = useState();
  const { t } = useTranslation();
  const location = useLocation();

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("clientId", clientId);
    body.append("notificationType", notificationType);
    body.append("isEnabled", isEnabled);
    body.append("isSelfBranding", isSelfBranding);
    return await axios.post(`${Config.apiUrl}/getnotificationpref`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getNotificationsSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
    setApiData(res.data);
  };
  console.log("notif", apiData);
  const getNotificationsError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getNotificationsMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getNotificationsSuccess,
    onError: getNotificationsError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getNotificationsMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  // ------- Edit Single Entry -------
  const EditNotificationFunction = async (values) => {
    const body = new FormData();
    body.append("clientId", clientId);
    body.append("notificationType", values.notificationType);
    body.append("isEnabled", values.isEnabled);
    body.append("isSelfBranding", values.isSelfBranding);
    return await axios.post(`${Config.apiUrl}/editnotificationpref`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditNotificationSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // getNotificationsMutate();
      setApiData(data.data);
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditNotification(false);
  };

  const onEditNotificationError = (data) => {
    setSelectedData("");
    setEditNotification(false);
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editNotificationMutate } =
    useMutation(EditNotificationFunction, {
      onSuccess: onEditNotificationSuccess,
      onError: onEditNotificationError,
    });

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {t("Notifications")}
                <span>/{location?.state?.name}</span>
              </Title>
              <Underline />
            </div>
          </div>

          {editNotification && (
            <EditNotificationModel
              editNotificationMutate={editNotificationMutate}
              editEntryLoading={editEntryLoading}
              setEditNotification={setEditNotification}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={apiData?.data || []}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditNotification={setEditNotification}
                userType={user.userType}
              />
              {(error || apiData?.msg == "No data found") && !isLoading && (
                <NotFoundModel />
              )}
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-primary-color hover:bg-hover-color  py-2.5 text-sm`;
const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;

export default Notifications;

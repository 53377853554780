import React from "react";
import tw from "tailwind-styled-components";
import { useFormik } from "formik";
import Loading from "../Loading";
import * as Yup from "yup";
import {
  CombineInputGroup,
  InputGroup,
  Label,
  SubmitBtn,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";

const EditPropertie = ({
  editPropertieMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const SubmitHandler = (values) => {
    editPropertieMutate(values);
  };

  const formik = useFormik({
    initialValues: {
      name: selectedData?.name || "",
      mobile: selectedData?.ownerMobile || "",
      status: selectedData?.status || "",
      id: selectedData?.id || "",
      lockInPeriod: selectedData?.lockInPeriod || "",
      noticePeriod: selectedData?.noticePeriod || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      mobile: Yup.string()
        .matches(/^\d{10}$/, "Enter Valid number")
        .required("Mobile number is required"),
      status: Yup.number()
        .required("Select Status")
        .min(1, "Status can be active or inactive"),
      id: Yup.number().required("Id is required"),
      lockInPeriod: Yup.number()
        .required("Lock In Period is required")
        .min(0, "Must be at least 0")
        .max(12, "Cannot be greater than 12")
        .typeError("Must be a number"),
      noticePeriod: Yup.number()
        .required("Notice Period is required")
        .min(0, "Must be at least 0")
        .max(12, "Cannot be greater than 12")
        .typeError("Must be a number"),
    }),
    onSubmit: SubmitHandler,
    enableReinitialize: true,
  });

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      {editEntryLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Edit Properties </Title>
          <form onSubmit={formik.handleSubmit}>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    fullWidth
                    name="name"
                    id="name"
                    autoComplete="off"
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <p className="text-rose-600 text-sm ">
                      {formik.errors.name}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="mobile">Phone</Label>
                  <FieldWrapper>
                    <Input
                      type="text"
                      name="mobile"
                      id="mobile"
                      disabled
                      autoComplete="off"
                      className=" bg-gray-100"
                      required
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.mobile}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Select
                      name="status"
                      id="status"
                      required
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      displayEmpty
                      className="w-full pl-2"
                    >
                      <MenuItem value="1">Pending</MenuItem>
                      <MenuItem value="2">Completed</MenuItem>
                      <MenuItem value="3">Active</MenuItem>
                      <MenuItem value="4">Inactive</MenuItem>
                    </Select>
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.mobile}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="lockInPeriod">Lock In Period</Label>
                  <FieldWrapper>
                    <Input
                      type="text"
                      name="lockInPeriod"
                      id="lockInPeriod"
                      autoComplete="off"
                      variant="outlined"
                      value={formik.values.lockInPeriod}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          /^\d{0,2}$/.test(value) &&
                          (value === "" ||
                            (Number(value) >= 0 && Number(value) <= 12))
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    {formik.touched.lockInPeriod &&
                    formik.errors.lockInPeriod ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.lockInPeriod}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="noticePeriod">Notice Period</Label>
                  <FieldWrapper>
                    <Input
                      type="text"
                      name="noticePeriod"
                      id="noticePeriod"
                      autoComplete="off"
                      variant="outlined"
                      value={formik.values.noticePeriod}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (
                          /^\d{0,2}$/.test(value) &&
                          (value === "" ||
                            (Number(value) >= 0 && Number(value) <= 12))
                        ) {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    {formik.touched.noticePeriod &&
                    formik.errors.noticePeriod ? (
                      <p className="text-rose-600 text-sm">
                        {formik.errors.noticePeriod}
                      </p>
                    ) : null}
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default EditPropertie;

import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import Model from "../Model";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import Loading from "../Loading";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const AddExtraCharges = ({
  setAddCharges,
  AddExtraChargesMutate,
  AddExtraChargesLoading,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const EXTRA_CHARGES_TYPE = [
    "Please Select Charges Type",
    "Electricity",
    "Registration",
    "Tech Charges",
    "Maintenance",
    "Others",
  ];

  const REPETATION = ["Please Select Repetition", "Once", "Monthly"];

  const ExtraChargesSchema = Yup.object().shape({
    chargesType: Yup.number()
      .min(1, "Invalid Charge Type")
      .max(5, "Invalid Charge Type")
      .required("Please select charges type"),
    amount: Yup.string()
      .required("Please specify the amount")
      .matches(/^[0-9]*$/, "Only Numbers are allowed")
      .test(
        "is-greater-than-zero",
        "Amount must be greater than 0",
        (value) => Number(value) > 0
      ),
    repetation: Yup.number()
      .min(1, "Invalid Repetition")
      .max(2, "Invalid Repetition")
      .required("Please select repetition")
      .when("chargesType", (chargesType, schema) => {
        if (chargesType === 1 || chargesType === 3) {
          return schema.oneOf([2], "Only Monthly Charges are allowed");
        }
        if (chargesType === 2) {
          return schema.oneOf([1], "Only One-Time Charges are allowed");
        }
        return schema;
      }),
  });

  const initialValues = {
    chargesType: "",
    amount: "",
    repetation: "",
  };

  const extraChargesFormik = useFormik({
    initialValues,
    validationSchema: ExtraChargesSchema,
    onSubmit: (values) => {
      AddExtraChargesMutate(values);
    },
  });

  useEffect(() => {
    const { chargesType } = extraChargesFormik.values;
    if (chargesType === 2) {
      extraChargesFormik.setFieldValue("repetation", "1");
      setIsDisabled(true);
    } else if (chargesType === 1 || chargesType === 3) {
      extraChargesFormik.setFieldValue("repetation", "2");
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [extraChargesFormik.values.chargesType]);

  return (
    <Model width="w-11/12 max-w-lg" setOpenModel={setAddCharges}>
      {AddExtraChargesLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Add Charges</Title>
          <form onSubmit={extraChargesFormik.handleSubmit}>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="chargesType">Charge Type</Label>
                <FieldWrapper>
                  <Select
                    onChange={extraChargesFormik.handleChange}
                    value={extraChargesFormik.values.chargesType}
                    name="chargesType"
                    className="w-full pl-2"
                  >
                    {EXTRA_CHARGES_TYPE.map((item, key) => (
                      <MenuItem key={key} value={key}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {extraChargesFormik.touched.chargesType &&
                    extraChargesFormik.errors.chargesType && (
                      <p className="text-rose-600 text-sm">
                        {extraChargesFormik.errors.chargesType}
                      </p>
                    )}
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label>
                  {extraChargesFormik.values.chargesType === 1
                    ? "Add Electricity Unit"
                    : "Add Amount ₹"}
                </Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    value={extraChargesFormik.values.amount}
                    name="amount"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (/^\d{0,10}$/.test(value)) {
                        extraChargesFormik.handleChange(e);
                      }
                    }}
                    className="border rounded mb-2"
                    placeholder={
                      extraChargesFormik.values.chargesType === 1
                        ? "Add Electricity Unit"
                        : "Add Amount ₹"
                    }
                  />
                  {extraChargesFormik.touched.amount &&
                    extraChargesFormik.errors.amount && (
                      <p className="text-rose-600 text-sm">
                        {extraChargesFormik.errors.amount}
                      </p>
                    )}
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="repetation">Repetition</Label>
                <FieldWrapper>
                  <Select
                    onChange={extraChargesFormik.handleChange}
                    value={extraChargesFormik.values.repetation}
                    name="repetation"
                    className="w-full pl-2"
                    disabled={isDisabled}
                  >
                    {REPETATION.map((item, key) => (
                      <MenuItem key={key} value={key}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <div className="text-rose-400 mb-4 text-xs md:text-sm lg:text-base">
                    {extraChargesFormik.touched.repetation &&
                      extraChargesFormik.errors.repetation}
                  </div> */}
                  {extraChargesFormik.touched.repetation &&
                    extraChargesFormik.errors.repetation && (
                      <p className="text-rose-600 text-sm">
                        {extraChargesFormik.errors.repetation}
                      </p>
                    )}
                </FieldWrapper>
              </InputGroup>
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Submit</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};
const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Input = tw.input`truncate border border-gray-300 border-m rounded-md pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

import React, { useEffect, useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Navbar from "../../Components/Navbar";
import { useLocation, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import { useSelector } from "react-redux";
import axios from "axios";
import { useMutation } from "react-query";
import Table from "../../Components/Eqaro/Table";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Loading from "../../Components/Loading";
import AddStaff from "../../Components/Staff/AddStaff";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Form, Formik, Field } from "formik";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import { toast } from "react-toastify";
import NotFoundModel from "../../Components/NotFoundModel";
import Register from "../../Components/Eqaro/Register";

const EqaroUserListing = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState();
  const [registerUser, setRegisterUser] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("email");
  const [userData, setUserData] = useState();

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("startDate", startDate);
    body.append("endDate", endDate);

    return await axios.post(`${Config.apiUrl}/getequarousers`, body, {
      headers: {
        authorization: "Bearer " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getListSuccess = (res) => {
    // console.log(res);
    setUserData(res.data);
  };

  const getListError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getListMutate,
    error,
    data,
    isRefetching,
    isSuccess,
  } = useMutation(fetchFunction, {
    onSuccess: getListSuccess,
    onError: getListError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getListMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    getListMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  const RegisterFunction = async (values) => {
    const body = new FormData();
    body.append("email", values.email);
    body.append("password", values.password);
    body.append("type", "Co-living/PG Operator");
    body.append("userType", "landlord");

    return await axios.post(`${Config.apiUrl}/registerlandlord`, body, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const RegisterSuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Registration successful!");
      // console.log(data?.data);

      setUserData(data.data);
    }
    setSelectedData("");
    setRegisterUser(false);
  };

  const RegisterError = (error) => {
    setRegisterUser(false);
    setSelectedData("");
    toast.error(
      error.response.data.errors.message ||
        "An Error occured during registration"
    );
  };

  const { isLoading: isRegisterLoading, mutate: onMutateRegister } =
    useMutation(RegisterFunction, {
      onSuccess: RegisterSuccess,
      onError: RegisterError,
    });

  // console.log(userData);
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Eqaro Users")}</Title>
              <Underline />
            </div>
            <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setRegisterUser(true)}
            >
              Add User
            </button>
          </div>
          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              ChangeHandler={ChangeHandler}
              filterValue={filterValue}
              filter={filter}
              user={user}
              t={t}
            />
          </div>

          {registerUser && (
            <Register
              onMutateRegister={onMutateRegister}
              isRegisterLoading={isRegisterLoading}
              setRegisterUser={setRegisterUser}
              data={data?.data}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
            />
          )}

          <TableWrapper>
            {!error && !isLoading && data && (
              <Table
                ApiData={userData.data}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            )}
            {(isLoading || isRefetching) && <Loading />}
            {(error || userData?.msg == "No data found") && !isLoading && (
              <NotFoundModel />
            )}
          </TableWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  setFilterValue,
  filterValue,
  ChangeHandler,
  t,
}) => {
  const today = moment().format("YYYY-MM-DD");
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <div className="flex gap-2 items-end sm:text-base text-xs flex-wrap">
            <div className="flex items-end gap-2">
              <InputGroup className="max-w-[130px]">
                <Label htmlFor="startDate">{t("Start_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="startDate"
                    id="startDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup className="max-w-[130px]">
                <Label htmlFor="endDate">{t("End_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="endDate"
                    id="endDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>
            </div>
            <ApplyBtn disabled={InitialLoading} type="submit">
              {t("Date_filter")}
            </ApplyBtn>
          </div>

          <div className="flex gap-4 items-end">
            {filter !== "status" && filter !== "paystatus" ? (
              <InputGroup className="max-w-[200px]">
                <Label htmlFor="filterVal">{t("")}</Label>
                <Field
                  placeholder="Search..."
                  type="text"
                  name="filterVal"
                  id="filterVal"
                  inputMode={filter === "mobile" ? "numeric" : "text"}
                  maxLength={filter === "mobile" ? "10" : undefined}
                  pattern={filter === "mobile" ? "[0-9]*" : undefined}
                  class="ml-1"
                  value={filterValue}
                  onChange={(e) => {
                    if (filter === "mobile") {
                      const value = e.target.value;
                      // Restrict input to digits only, up to 10 characters
                      if (/^\d{0,10}$/.test(value)) {
                        ChangeHandler(e);
                      }
                    } else {
                      ChangeHandler(e);
                    }
                  }}
                  className="border border-slate-300 rounded-md px-4 focus:ring-1 focus:ring-primary-color focus:border-primary-color py-2"
                />
              </InputGroup>
            ) : filter === "status" ? (
              <InputGroup
                className="min-w-[120px]"
                style={{ flexBasis: "content" }}
              >
                <Label htmlFor="filter">{t("Select")}</Label>
                <Select
                  autoComplete="off"
                  className="px-2 bg-transparent text-sm border-none"
                  style={{ height: "40px" }}
                  value={filterValue}
                  onChange={ChangeHandler}
                >
                  <MenuItem value="" disabled>
                    {t("Select Status")}
                  </MenuItem>
                  <MenuItem value="2">{t("Success")}</MenuItem>
                  <MenuItem value="1">{t("Pending")}</MenuItem>
                  <MenuItem value="3">{t("Failed")}</MenuItem>
                </Select>
              </InputGroup>
            ) : null}

            <InputGroup
              className="min-w-[120px]"
              style={{ flexBasis: "content" }}
            >
              <Label htmlFor="filter">{t("Search By")}</Label>

              <Select
                autoComplete="off"
                className="w-24 px-2 bg-transparent text-sm border-none"
                style={{ height: "40px" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setFilterValue("");
                }}
              >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="profileId">Profile Id</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </Select>
            </InputGroup>
          </div>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-primary-color hover:bg-hover-color  py-2.5 text-sm`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;

export default EqaroUserListing;

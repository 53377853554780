import React, { useState } from "react";
import tw from "tailwind-styled-components";
import Loading from "../Loading";
import Config from "../../Config";
import { SubmitBtn } from "../Styles/InputStyles";
import Model from "../Model";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const RoomDoesNotContainsOptions = ({
  setRoomUser,
  selectedData,
  roomEntryLoading,
  roomOptions,
  roomOwnerMutate,
}) => {
  const [selectedRoomOption, setSelectedRoomOption] = useState(0);
  const { id } = useParams();

  const submitFunction = async () => {
    if (
      selectedData.roomId !== undefined &&
      selectedRoomOption !== undefined &&
      roomOptions.length
    ) {
      const body = new FormData();
      body.append("roomId", selectedData.roomId);
      body.append("optionId", roomOptions[selectedRoomOption].id);
      body.append("propId", id);
      roomOwnerMutate(body);
    }
  };

  return (
    <Model width={`w-[400px]`} setOpenModel={setRoomUser}>
      {roomEntryLoading ? (
        <Loading />
      ) : (
        <>
          <Title>
            Room Number{" "}
            <span className="text-primary-color">
              {" "}
              {selectedData?.roomNum}{" "}
            </span>
          </Title>

          {/* <Title>Room Options </Title> */}
          {roomOptions.length > 0 && (
            <Wrapper>
              <p
                htmlFor="name"
                className="mb-1 text-xs md:text-sm font-semibold text-gray-700"
              >
                Room Type
              </p>

              {roomEntryLoading && <Loading />}
              {roomOptions.length >= 0 && !roomEntryLoading && (
                <Select
                  className="w-full pl-2"
                  onChange={(e) => {
                    setSelectedRoomOption(e.target.value);
                  }}
                >
                  {roomOptions.length >= 0 ? (
                    roomOptions.map((item, key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {item.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <option>Room Options not available !!!</option>
                  )}
                </Select>
              )}

              {roomOptions.length >= 0 && !roomEntryLoading && (
                <div>
                  <div className="flex flex-wrap gap-2 mt-6">
                    <p className="bg-gray-700 text-gray-100 rounded-md px-3 py-1 text-xs md:text-sm">
                      Rent: ₹{roomOptions[selectedRoomOption]?.rent}
                    </p>
                    <p className="bg-gray-700 text-gray-100 rounded-md px-3 py-1 text-xs md:text-sm">
                      Type:{" "}
                      {
                        Config.roomTypeMapping[
                          roomOptions[selectedRoomOption]?.type
                        ]
                      }
                    </p>
                  </div>
                  <div className="flex flex-wrap gap-2 mt-6">
                    {roomOptions[selectedRoomOption]?.amenities
                      ?.split(",")
                      .map((amenity, index) => (
                        <span
                          key={index}
                          className="bg-primary-color text-white rounded-full px-3 py-1 text-xs md:text-sm"
                        >
                          {amenity.trim()}
                        </span>
                      ))}
                  </div>
                </div>
              )}
            </Wrapper>
          )}
          {
            <BtnWrapper className="flex justify-center items-center">
              <SubmitBtn type="button" onClick={() => submitFunction()}>
                Submit
              </SubmitBtn>
            </BtnWrapper>
          }
          {/* <ToastContainer /> */}
        </>
      )}
    </Model>
  );
};

const Wrapper = tw.div`mt-3 mb-3`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl text-gray-700 font-bold text-left`;
const BtnWrapper = tw.div`w-full space-x-10 mt-8`;

export default RoomDoesNotContainsOptions;

import React from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Label, SubmitBtn } from "../Styles/InputStyles";
import Loading from "../Loading";
import { dueTypes } from "../../Config";


const AddDues = ({
  addDuesMutate,
  addDuesLoading,
  setAddDues,
  selectedData,
}) => {
  console.log(selectedData);
  const SubmitHandler = (values) => {
    addDuesMutate(values);
  };

  const formik = useFormik({
    initialValues: {
      dueType: "",
      amount: "",
    },
    validationSchema: Yup.object().shape({
      dueType: Yup.string().required("Please select a type"),
      amount: Yup.number()
        .required("Please enter amount")
        .min(0, "Due amount cannot be less than 0")
        .typeError("Due amount must be a number"),
    }),
    onSubmit: SubmitHandler,
  });

  return (
    <Model width="w-11/12 max-w-md" setOpenModel={setAddDues}>
      {addDuesLoading && <Loading />}
      {!addDuesLoading && (
        <Wrapper>
          <Title>Add Dues</Title>
          <form onSubmit={formik.handleSubmit}>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="dueType">Due Type</Label>
                <FieldWrapper>
                  <Select
                    name="dueType"
                    id="dueType"
                    value={formik.values.dueType}
                    onChange={formik.handleChange}
                    className="w-full pl-2"
                  >
                    {dueTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.dueType && formik.errors.dueType ? (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.dueType}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="amount">Amount ₹</Label>
                <FieldWrapper>
                  <Input
                    size="small"
                    type="text"
                    name="amount"
                    id="salary outlined-size-small"
                    autoComplete="off"
                    className="truncate"
                    variant="outlined"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (/^\d{0,8}$/.test(value)) {
                        formik.handleChange(e);
                      }
                    }}
                    value={formik.values.amount}
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.amount}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>


            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Submit</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

// Styled components
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Wrapper = tw.div`px-4 my-2 space-y-4`;
const InputGroup = tw.div`w-full`;
const FieldWrapper = tw.div`w-full`;
const CombineInputGroup = tw.div`flex space-x-4`;
const Input = tw.input`truncate border border-gray-400 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default AddDues;

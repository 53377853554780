import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Flats/Tables";
import Navbar from "../../Components/Navbar";
import { toast } from "react-toastify";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";

import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import EditFlats from "../../Components/Flats/EditFlats";
import RoomDetail from "../../Components/Flats/RoomDetail";
import ViewDetail from "../../Components/Flats/ViewDetail";

const Flats = () => {
  const { id: propId } = useParams();
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState("");
  const [flatData, setFlatData] = useState(null);
  const [roomOptions, setRoomOptions] = useState(undefined);
  // const [selectedData, setSelectedData] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  // const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);
  const [roomUser, setRoomUser] = useState(false);
  const [addFlats, setAddFlats] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [apiData, setApiData] = useState();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("propId", propId);

    return await axios.post(`${Config.apiUrl}/getflats`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getFlatsSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
    setApiData(res.data);
  };

  const getFlatSettled = (res) => {
    if (res.statusText === "OK") {
      setRoomOptions(res.data.roomOptions);
    }
  };
  const getFlatsError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getFlatsMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getFlatsSuccess,
    onError: getFlatsError,
    onSettled: getFlatSettled,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getFlatsMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    getFlatsMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  //------- Edit Single Entry -------
  const EditTenantFunction = async (values) => {
    const body = new FormData();
    body.append("propId", propId);
    body.append("flat", values.flat);
    body.append("roomNum", values.room);
    body.append("repeat", values.repeat);
    return await axios.post(`${Config.apiUrl}/editflatsnumber`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      setApiData(data.data);
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editOwnerMutate } = useMutation(
    EditTenantFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  //-------RoomDetail-------
  const RoomDetailTenantFunction = async (values) => {
    const body = new FormData();
    body.append("propId", propId);
    body.append("roomNum", values.room);
    return await axios.post(`${Config.apiUrl}/getflatsoption`, body, {
      headers: {
        authorization: "Bearer " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const onRoomDetailEntrySuccess = (data) => {
    //Figuring out the data

    if (data?.data?.status === "OK") {
      setFlatData(data);
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    //setSelectedData("");
  };

  const onRoomDetailEntryError = (data) => {
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: roomDetailEntryLoading, mutate: roomDetailOwnerMutate } =
    useMutation(RoomDetailTenantFunction, {
      onSuccess: onRoomDetailEntrySuccess,
      onError: onRoomDetailEntryError,
    });

  //-------Room Edit Single Entry -------
  const RoomTenantFunction = async (values) => {
    const body = new FormData();
    body.append("propId", propId);
    body.append("flat", values.flat);
    body.append("roomNum", values.room);

    return await axios.post(`${Config.apiUrl}/flatsnumber`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onRoomEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getFlatsMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setRoomUser(false);
  };

  const onRoomEntryError = (data) => {
    setRoomUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: roomEntryLoading, mutate: roomOwnerMutate } = useMutation(
    RoomTenantFunction,
    {
      onSuccess: onRoomEntrySuccess,
      onError: onRoomEntryError,
    }
  );
  const RoomOptionIdPost = async (body) => {
    return await axios.post(`${Config.apiUrl}/updateflatsoptionid`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const RoomOptionIsSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      setApiData(data.data);
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setRoomUser(false);
  };

  const RoomOptionIsError = (data) => {
    setRoomUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: RoomOptionIdIsLoading, mutate: RoomOptionIdMutate } =
    useMutation(RoomOptionIdPost, {
      onSuccess: RoomOptionIsSuccess,
      onError: RoomOptionIsError,
    });
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        {/* <Page> */}
        <BoxContainer>
          {/* Heading */}
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {t("Flats")}
                <span>/{location?.state?.name}</span>
              </Title>
              <Underline />
            </div>
          </div>

          {editUser && (
            <EditFlats
              editOwnerMutate={editOwnerMutate}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {/* Room Details */}
          {roomUser && (
            <RoomDetail
              roomOwnerMutate={RoomOptionIdMutate}
              roomEntryLoading={RoomOptionIdIsLoading}
              setRoomUser={setRoomUser}
              data={data?.data}
              selectedData={selectedData}
              flatData={flatData}
              roomOptions={roomOptions}
            />
          )}

          {viewDetail && (
            <ViewDetail
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : apiData.flatDetails}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditUser={setEditUser}
                setRoomUser={setRoomUser}
                setViewDetail={setViewDetail}
                userType={user.userType}
                propId={propId}
                setAddFlats={setAddFlats}
                roomDetailOwnerMutate={roomDetailOwnerMutate}
              />
              {(error || apiData?.flatDetails?.length === 0) && !isLoading && (
                <NotFoundModel />
              )}
            </TableWrapper>
          )}
          {(isLoading || isRefetching) && <Loading />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap  font-medium`;

export default Flats;

import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import React, { useState } from "react";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import Model from "../Model";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import Loading from "../Loading";

const AddStaff = ({
  setAddBank,
  mutateBank,
  isLoadingBank,
  isLoadingUPI,
  mutateUPI,
}) => {
  const [detailType, setDetailType] = useState("1");

  const formikBank = useFormik({
    // validateOnBlur:false,
    // validateOnChange:false,
    initialValues: {
      holderName: "",
      bankName: "",
      accountNumber: "",
      ifsc: "",
      bankAddress: "",
    },
    validationSchema: Yup.object().shape({
      holderName: Yup.string().required("Holder Name is required"),
      bankName: Yup.string().required("Bank Name is required"),
      accountNumber: Yup.string()
        .matches(/^\d{9,16}$/, "Enter Valid Account Number")
        .required("Account Number is required"),
      ifsc: Yup.string().required("IFSC is required"),
      bankAddress: Yup.string().required("Bank Address is required"),
    }),
    onSubmit: async (values) => {
      mutateBank(values);
    },
  });

  const formikUPI = useFormik({
    // validateOnBlur:false,
    // validateOnChange:false,
    initialValues: {
      upiId: "",
    },
    validationSchema: Yup.object().shape({
      upiId: Yup.string().required("UPI Id is required"),
    }),
    onSubmit: async (values) => {
      //mutateUpi(values);
      mutateUPI(values);
    },
  });

  return (
    <Model width="w-11/12 max-w-lg" setOpenModel={setAddBank}>
      <Wrapper>
        <Title>Add Bank Detail's</Title>
        <ButtonGroup>
          <TemplateButton
            isActive={detailType === "1"}
            onClick={() => setDetailType("1")}
          >
            Bank Details
          </TemplateButton>
          <TemplateButton
            ml
            isActive={detailType === "2"}
            onClick={() => setDetailType("2")}
          >
            UPI Details
          </TemplateButton>
        </ButtonGroup>
        <Seperator />
        {isLoadingBank && <Loading />}
        {isLoadingUPI && <Loading />}
        {!isLoadingBank && !isLoadingUPI && (
          <div>
            {detailType === "1" && (
              <>
                <form onSubmit={formikBank.handleSubmit}>
                  <Wrapper>
                    <InputGroup>
                      <Label htmlFor="holderName">Beneficiary's Name</Label>
                      <FieldWrapper>
                        <Input
                          type="text"
                          name="holderName"
                          autoComplete="off"
                          value={formikBank.values.holderName}
                          onChange={formikBank.handleChange}
                          //onBlur={formikBank.handleBlur}
                        />
                        {formikBank.touched.holderName &&
                        formikBank.errors.holderName ? (
                          <p className="text-rose-600 text-sm ">
                            {formikBank.errors.holderName}
                          </p>
                        ) : null}
                      </FieldWrapper>
                    </InputGroup>

                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="accountNumber">Account Number</Label>
                        <FieldWrapper>
                          <Input
                            type="text"
                            name="accountNumber"
                            autoComplete="off"
                            value={formikBank.values.accountNumber}
                            onChange={(e) => {
                              const { value } = e.target;
                              if (/^\d{0,16}$/.test(value)) {
                                formikBank.handleChange(e);
                              }
                            }}
                            InputProps={{ inputProps: { maxLength: 16 } }}

                            //onBlur={formikBank.handleBlur}
                          />
                          {formikBank.touched.accountNumber &&
                          formikBank.errors.accountNumber ? (
                            <p className="text-rose-600 text-sm">
                              {formikBank.errors.accountNumber}
                            </p>
                          ) : null}
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="bankName">Bank Name</Label>
                        <FieldWrapper>
                          <Input
                            type="text"
                            name="bankName"
                            autoComplete="off"
                            value={formikBank.values.bankName}
                            onChange={formikBank.handleChange}
                            //onBlur={formikBank.handleBlur}
                          />
                          {formikBank.touched.bankName &&
                          formikBank.errors.bankName ? (
                            <p className="text-rose-600 text-sm">
                              {formikBank.errors.bankName}
                            </p>
                          ) : null}
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>

                    <CombineInputGroup>
                      <InputGroup>
                        <Label htmlFor="ifsc">IFSC</Label>
                        <FieldWrapper>
                          <Input
                            type="text"
                            name="ifsc"
                            id="ifsc outlined-size-small"
                            autoComplete="off"
                            variant="outlined"
                            value={formikBank.values.ifsc}
                            onChange={formikBank.handleChange}
                            //onBlur={formikBank.handleBlur}
                          />
                          {formikBank.touched.ifsc && formikBank.errors.ifsc ? (
                            <p className="text-rose-600 text-sm">
                              {formikBank.errors.ifsc}
                            </p>
                          ) : null}
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="address">Branch Address</Label>
                        <FieldWrapper>
                          <Input
                            type="text"
                            name="bankAddress"
                            id="bankAddress outlined-size-small"
                            autoComplete="off"
                            variant="outlined"
                            value={formikBank.values.bankAddress}
                            onChange={formikBank.handleChange}
                            //onBlur={formikBank.handleBlur}
                          />
                          {formikBank.touched.bankAddress &&
                          formikBank.errors.bankAddress ? (
                            <p className="text-rose-600 text-sm">
                              {formikBank.errors.bankAddress}
                            </p>
                          ) : null}
                        </FieldWrapper>
                      </InputGroup>
                    </CombineInputGroup>
                  </Wrapper>

                  <BtnWrapper>
                    <SubmitBtn type="submit">Submit</SubmitBtn>
                  </BtnWrapper>
                </form>
              </>
            )}

            {detailType === "2" && (
              <>
                <form onSubmit={formikUPI.handleSubmit}>
                  <Wrapper>
                    <InputGroup>
                      <Label htmlFor="upiId">UPI ID </Label>
                      <FieldWrapper>
                        <Input
                          type="upiId"
                          name="upiId"
                          autoComplete="off"
                          value={formikUPI.values.upiId}
                          onChange={formikUPI.handleChange}
                          //onBlur={formikBank.handleBlur}
                        />
                        {formikUPI.touched.upiId && formikUPI.errors.upiId ? (
                          <p className="text-rose-600 text-sm ">
                            {formikUPI.errors.upiId}
                          </p>
                        ) : null}
                      </FieldWrapper>
                    </InputGroup>
                  </Wrapper>

                  <BtnWrapper>
                    <SubmitBtn type="submit">Submit</SubmitBtn>
                  </BtnWrapper>
                </form>
              </>
            )}
          </div>
        )}
      </Wrapper>
    </Model>
  );
};
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-6`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const CombineInputGroup = tw.div`flex space-x-7`;
const ButtonGroup = tw.div`flex items-center !gap-0 mt-6 `;
const Input = tw.input`truncate border border-gray-300 border-m rounded-md pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

const TemplateButton = tw.button`
  pl-0 pr-2 font-medium text-sm relative ${(props) =>
    props.isActive ? "text-blue-500" : "text-gray-400"}
  ${(props) => (props.ml ? "ml-4" : "")}
  after:absolute after:top-7 after:left-0 after:w-full after:h-[0.06rem]
  ${(props) => (props.isActive ? "after:bg-blue-500" : "")}
`;
const Seperator = tw.div`w-full h-[0.05rem] bg-gray-200 my-2 `;

export default AddStaff;

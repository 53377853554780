import React from "react";
import tw from "tailwind-styled-components";
import Model from "../Model";
import moment from "moment";
import Images from "../../Images";
import { AiOutlineCloudUpload } from "react-icons/ai";

const ViewDetailModel = ({
  setViewDetail,
  selectedData,
  setUploadLogo,
  setSelectedData,
}) => {
  const logo = () => {
    setViewDetail(false);
    setSelectedData(selectedData);
    setUploadLogo(true);
  };

  const getStatus = (status) => {
    if (status == 1) {
      return <Unverified>Unverified</Unverified>;
    } else if (status == 2) {
      return <Verified>Verified</Verified>;
    } else if (status == 3) {
      return <Active>Active</Active>;
    } else {
      return <Enrolled>Enrolled</Enrolled>;
    }
  };

  const getId = (id) => {
    if (id) {
      return <PropertyId>#{id}</PropertyId>;
    } else {
      return <Unverified>N/A</Unverified>;
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title>View Details</Title>
        <div className="flex my-4 justify-between items-center">
          <div className="flex">
            <Status>{getId(selectedData?.id) || "N/A"}</Status>
            <Status className="ml-3">
              {getStatus(selectedData?.status) || "N/A"}
            </Status>
          </div>
          <Logo title="Upload Logo" onClick={logo} className="ml-3">
            <AiOutlineCloudUpload className="mr-1" />
            Upload Logo
          </Logo>
        </div>
        <Column>
          <FirstColumn>
            <SubHeading>Name</SubHeading>
            <Paragraph>{selectedData?.name || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Phone Number</SubHeading>
            <Paragraph>{selectedData?.mobile || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>City</SubHeading>
            <Paragraph>{selectedData?.city || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Property Count</SubHeading>
            <Paragraph>{selectedData?.propCount || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Created At</SubHeading>
            <Paragraph>
              {moment(selectedData?.createdAt).format("LL") || "N/A"}
            </Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Updated At</SubHeading>
            <Paragraph>
              {moment(selectedData?.updatedAt).format("LL") || "N/A"}
            </Paragraph>
          </FirstColumn>
        </Column>

        {/* <div className="flex justify-center items-center w-full mt-5">
          <span
            title="Upload Logo"
            onClick={logo}
            className="cursor-pointer hover:underline  text-center text-gray-600"
          >
            <img
              src={Images.Arrow}
              alt="arrow"
              className="w-2 rotate-90 opacity-80 "
            />
             Upload Logo 
          </span>
        </div> */}

        {/* <SingleItem name={"Name"} value={selectedData?.name || "N/A"} />
        <SingleItem name={"Phone Number"} value={selectedData?.mobile || "N/A"} />
        <SingleItem name={"City"} value={selectedData?.city || "N/A"} />
        <SingleItem name={"Propery Count"} value={selectedData?.propertyCount} />
        <SingleItem name={"Created At"} value={moment(selectedData?.createdAt).format("LL") || "N/A"} />
        <SingleItem name={"Updated At"} value={moment(selectedData?.updatedAt).format("LL") || "N/A"} /> */}
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    <Value>{value}</Value>
  </div>
);
const Status = tw.div``;
const Logo = tw.div`border border-gray-600 text-gray-700 text-xs  inline-block py-1 max-w-fit px-3 flex items-center justify-center rounded-full  cursor-pointer hover:underline hover:decoration-gray-600`;
const PropertyId = tw.div`border border-cyan-600 text-cyan-600 text-xs  inline-block py-1 max-w-fit px-3 flex items-center justify-center rounded-full`;
const Active = tw.div`border border-emerald-600 text-emerald-600 text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Enrolled = tw.div`border border-yellow-600 text-yellow-600 text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Verified = tw.div`border border-purple-600 text-purple-600 text-xs inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Unverified = tw.div`border border-rose-600 text-rose-600 text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const FirstColumn = tw.div``;
const Column = tw.div`grid grid-cols-2 my-3 gap-2`;
const SubHeading = tw.h2`text-xs text-blue-500 font-[600] mb-1`;
const Paragraph = tw.p`text-sm text-gray-900 font-semibold`;
export default ViewDetailModel;

import React from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import { Formik, Field, Form, useFormik } from "formik";
import Loading from "../Loading";
import { Label, SubmitBtn } from "../Styles/InputStyles";

const EditFloors = ({
  editOwnerMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const handleSubmit = (values) => {
    const formattedValues = { ...values };
    editOwnerMutate(formattedValues);
  };

  const formik = useFormik({
    initialValues: {
      floor: selectedData || "",
      repeat: false,
    },
    validationSchema: Yup.object().shape({
      room: Yup.string()
        .matches(/^\d{1}$/, "Only numbers are allowed")
        .test("range", "Value must be between 1 and 9", (value) => {
          return value === "" || (Number(value) >= 1 && Number(value) <= 9);
        }),
      repeat: Yup.boolean(),
    }),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      {editEntryLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Edit Rooms</Title>
          <hr className="text-gray-600" />
          <form onSubmit={formik.handleSubmit}>
            <Wrapper>
              <InputGroup>
                <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">
                  Number of Rooms
                </p>
                <Label htmlFor="room">
                  Set number of rooms to increase or decrease on the floor
                </Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="room"
                    value={formik.values.room}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter number of rooms"
                  />
                  {formik.touched.room && formik.errors.room ? (
                    <p className="text-rose-600 text-sm">
                      {formik.errors.room}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>

              {/*<hr className="mt-5 text-gray-600 mb-3" />

                   <InputGroup>
                    <FieldWrapper>
                      <div className="flex items-center justify-between">
                        <Label htmlFor="repeat">Replicate</Label>
                        <label className="inline-flex items-center cursor-pointer">
                          <Field
                            type="checkbox"
                            name="repeat"
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
                        </label>
                      </div>
                      <p className="text-xs md:text-sm lg:text-base text-gray-600 mt-1">
                        Set if you want to increase or decrease room on every
                        floor
                      </p>
                    </FieldWrapper>
                  </InputGroup> */}
            </Wrapper>

            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

const Wrapper = tw.div`px-1 space-y-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const InputGroup = tw.div`w-full space-y-2`;
const FieldWrapper = tw.div`w-full`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default EditFloors;

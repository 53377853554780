import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import Table from "../../Components/ExpenseType/Table";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import NotFoundModel from "../../Components/NotFoundModel";
import {
  Bg,
  BoxContainer,
  Button,
  Underline,
} from "../../Components/Styles/PageStyles";
import Config from "../../Config";
import Images from "../../Images";
import AddExpense from "../../Components/ExpenseType/AddType";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import EditExpense from "../../Components/ExpenseType/EditExpense";
import { MdAdd } from "react-icons/md";

export const ExpensesType = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState();
  const [addExpensesType, setAddExpensesType] = useState(false);
  const [editData, setEditData] = useState(false);
  const [apiData, setApiData] = useState();
  const location = useLocation();

  const fetchData = async () => {
    const body = new FormData();
    body.append("id", location.state.id);
    return await axios.post(`${Config.apiUrl}/getexpensetype`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onDataSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
    setApiData(res.data);
  };

  const onDataError = (data) => {};
  useEffect(() => {
    const handler = setTimeout(() => {
      DataMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  const {
    isLoading: isDataLoading,
    mutate: DataMutate,
    error,
    data,
  } = useMutation(fetchData, {
    onSuccess: onDataSuccess,
    onError: onDataError,
  });

  const addData = async (values) => {
    const body = new FormData();
    body.append("categoryId", location.state.id);

    Object.keys(values).forEach((i) => body.append(i, values[i]));

    return await axios.post(`${Config.apiUrl}/addexpensetype`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddSuccess = (data) => {
    toast.success(data?.data?.msg || "Success");
    // DataMutate();
    setApiData(data?.data);
    setAddExpensesType(false);
  };

  const onAddError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occured");
    // DataMutate();
    setAddExpensesType(false);
  };

  const { isLoading: addExpenseLoading, mutate: addExpenseMutate } =
    useMutation(addData, {
      onSuccess: onAddSuccess,
      onError: onAddError,
    });

  const editDataPost = async (values) => {
    const body = new FormData();

    Object.keys(values).forEach((i) => body.append(i, values[i]));

    return await axios.post(`${Config.apiUrl}/editexpensetype`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditSuccess = (data) => {
    toast.success(data?.data?.msg || "Success");
    setApiData(data?.data);
    // DataMutate();
    setEditData(false);
  };

  const onEditError = (data) => {
    toast.error(data?.response?.data?.msg || "An Error Occured");
    // DataMutate();
    setEditData(false);
  };

  const { isLoading: editIsLoading, mutate: editMutate } = useMutation(
    editDataPost,
    {
      onSuccess: onEditSuccess,
      onError: onEditError,
    }
  );

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {"Expense Type "}
                {"\u00A0"}
                <span className="text-primary-color">
                  ( {location?.state?.expense} )
                </span>
              </Title>
              <Underline />
            </div>
            {/* <button
              className="bg-primary-color text-white p-2 rounded-md flex  justify-center items-center"
              onClick={() => setAddExpensesType(true)}
            >
              Add Type
            </button> */}
            <UploadWrapper>
              <Button type="button" onClick={() => setAddExpensesType(true)}>
                <MdAdd className="text-white w-5 h-5" />{" "}
                <p className="text-sm">{"Add Type"}</p>
              </Button>
            </UploadWrapper>
          </div>

          {addExpensesType && (
            <AddExpense
              setAddExpenses={setAddExpensesType}
              loading={addExpenseLoading}
              mutate={addExpenseMutate}
            />
          )}

          {editData && (
            <EditExpense
              setEditData={setEditData}
              selectedData={selectedData}
              loading={editIsLoading}
              mutate={editMutate}
            />
          )}

          {!error && !isDataLoading && data && (
            <TableWrapper>
              <Table
                ApiData={apiData?.data}
                setSelectedData={setSelectedData}
                userType={user.userType}
                setEditData={setEditData}
              />
              {(error || apiData?.msg == "No data found") && !isDataLoading && (
                <NotFoundModel />
              )}
            </TableWrapper>
          )}
          {isDataLoading && <Loading />}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Title = tw.h1`text-xl w-full text-gray-800 whitespace-nowrap font-medium flex justift-center`;
const TableWrapper = tw.h1`my-10 border  bg-white rounded-md `;
const UploadWrapper = tw.div`w-full flex justify-end space-x-4 items-center `;

import React from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import Loading from "../Loading";
import { Formik, Form, Field, ErrorMessage } from "formik";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Za-z]/, "Password must contain at least one letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .required("Password is required"),

});

const Register = ({
    onMutateRegister,
    isRegisterLoading,
    setRegisterUser,
}) => {

    const InitialValues = {
        email: "",
        password: "",
        type: "Co-living/PG Operator",
        userType: "landlord",
    };
    const handleSubmit = (values, { setSubmitting }) => {
        onMutateRegister(values);
        setSubmitting(false);
    };

    return (
        <Model width="w-11/12 max-w-md" setOpenModel={setRegisterUser}>
            {isRegisterLoading && <Loading />}
            {!isRegisterLoading && (
                <FormWrapper>
                    <HeadingWrapper>
                        <Heading>Equaro Registration</Heading>
                        <Subheading>
                            Create an account to start managing your tasks.
                        </Subheading>
                    </HeadingWrapper>
                    <Formik
                        initialValues={InitialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {/* {({ isSubmitting, setFieldValue, errors, touched }) => ( */}
                        <Form className="space-y-6">
                            <div>
                                <Label htmlFor="email">Email</Label>
                                <Field name="email" type="email" as={Input} />
                                <ErrorMessage name="email" component={ErrorText} />
                            </div>

                            <div>
                                <Label htmlFor="password">Password</Label>
                                <Field name="password" type="password" as={Input} />
                                <ErrorMessage name="password" component={ErrorText} />
                            </div>

                            <div>
                                <Button type="submit">Register</Button>
                            </div>
                        </Form>
                        {/* )} */}
                    </Formik>
                </FormWrapper>
            )}
        </Model>
    );
};


const Label = tw.label`block text-sm font-medium text-gray-700 mb-1`;
const Input = tw.input`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`;
const Button = tw.button`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-color hover:bg-hover-color focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50`;
const ErrorText = tw.div`text-red-500 text-sm mt-1`;
const HeadingWrapper = tw.div`text-center mb-4`;
const Heading = tw.h1`text-3xl font-extrabold text-gray-900`;
const Subheading = tw.p`text-xs sm:text-sm text-gray-600 mt-2`;
const FormWrapper = tw.div` py-6 sm:py-8 px-4 `;
export default Register;

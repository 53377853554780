import React, { useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loading from "../Loading";
import { InputGroup, Label } from "../Styles/InputStyles";

const AddFloor = ({
  setViewAddFloors,
  selectedData,
  AddFloorMutate,
  AddFloorLoading,
}) => {
  const InitialValue = {
    floorCount: "",
  };

  const addFloorSchema = Yup.object().shape({
    floorCount: Yup.string().required("Enter the floor count"),
  });
  const addFloorFormik = useFormik({
    initialValues: InitialValue,
    validationSchema: addFloorSchema,
    onSubmit: (values) => {
      const payload = {
        ...values,
        type: selectedData.type,
      };
      AddFloorMutate(payload);
    },
  });

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewAddFloors}>
      {AddFloorLoading ? (
        <div className="w-full h-full">
          <Loading />
        </div>
      ) : (
        <Wrapper>
          <Title>Add {selectedData.type === 1 ? "Floors" : "Flats"}</Title>
          {/* <h2 className="text-sm md:text-lg lg:text-lg text-gray-600 font-medium ">
            Number of {selectedData.type === 1 ? "Floors" : "Flats"}
          </h2> */}
          <form onSubmit={addFloorFormik.handleSubmit}>
            <InputGroup>
              <Label>
                Set number of {selectedData.type === 1 ? "floors" : "flats"} to be increased in the property.
              </Label>
              <FieldWrapper>
                <Input
                  type="text"
                  name="floorCount"
                  value={addFloorFormik.values.floorCount}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      /^\d{0,2}$/.test(value) &&
                      (value === "" ||
                        (Number(value) >= 1 && Number(value) <= 15))
                    ) {
                      addFloorFormik.handleChange(e);
                    }
                  }}
                  onBlur={addFloorFormik.handleBlur}
                  placeholder={`Enter number of ${
                    selectedData.type === 1 ? "floors" : "flats"
                  }`}
                />
                <p className="text-rose-500 text-sm">
                  {addFloorFormik.touched.floorCount &&
                    addFloorFormik.errors.floorCount}
                </p>
              </FieldWrapper>
            </InputGroup>
            <BtnWrapper>
              <input
                type="submit"
                value="Submit"
                className="text-sm  w-32  md:w-44 h-12 grid place-items-center md:text-base bg-primary-color hover:bg-hover-color text-white rounded-md shadow-md`;"
              />
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};
const Wrapper = tw.div`grid gap-2 my-6 px-1 `;
const FieldWrapper = tw.div`w-full`;
const Input = tw.input`truncate border border-gray-300 border-m rounded-md pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-5 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-4`;

export default AddFloor;

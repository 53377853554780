import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config, { PaginationLimit } from "../../Config";
import { MdModeEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { IoMdMore, IoIosCreate } from "react-icons/io";

const Table = ({
  ApiData,
  setSelectedData,
  setEditUser,
  setViewDetail,
  userType,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [previousId, setPreviousId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActionMenu, setOpenActionMenu] = useState(null);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const ActionBtns = (item) => {
    const openMenu = (id) => {
      console.log("Button clicked, ID:", id);
      setPreviousId(openActionMenu);
      setOpenActionMenu((prevId) => {
        console.log("Previous open menu ID:", prevId);
        return prevId === id ? null : id;
      });
    };

    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            {userType != Config.userType.MARKETING && (
              <MenuItem title="Edit Complaints" onClick={() => edit(item)}>
                <MdModeEdit className="text-gray-700 mr-2" /> Edit Complaints
              </MenuItem>
            )}
            <MenuItem title="View Detail" onClick={() => viewDetail(item)}>
              <IoMdEye className="text-gray-700 mr-2" /> View Detail
            </MenuItem>
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        tenantName: tenantNames(item),
        title: getTitle(item),
        description: getDescription(item),
        status: getStatus(item.status),
        action: ActionBtns(item),
      }));
      setData(tempData);
    }
  }, [ApiData, openActionMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#menuOptions") &&
        !event.target.closest(".action-btn")
      ) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getTenantGender = (tenantGender) => {
    if (tenantGender === 1) {
      return "Male";
    } else if (tenantGender === 2) {
      return "Female";
    } else {
      return "Both";
    }
  };

  const tenantNames = (item) => {
    return (
      <div className="flex gap-1 items-center">
        <img
          src="/images/avatar.png"
          alt="Client"
          className="w-10 h-10 rounded-full overflow-hidden"
        />
        <div className="flex flex-col gap-1">
          <span className=" font-medium text-zinc-900">
            {item.tenantName}{" "}
            <span className="text-xs bg-zinc-100 text-zinc-500 px-1 py-0.5 rounded-sm">
              {getTenantGender(item.tenantGender)}
            </span>
          </span>
          <span className="text-amber-500 text-xs">#{item.tenantMobile}</span>
        </div>
      </div>
    );
  };

  const getTitle = (item) => {
    // return (
    //   <span className=" capitalize flex gap-2 items-center">
    //     {item.title}
    //   </span>
    // );
    const isLongTitle = item.title.length > 30;
    return (
      <div>
        <a
          data-tooltip-id={`tooltip-${item.id}`}
          data-tooltip-content={isLongTitle ? item.title : ""}
          data-tooltip-delay-show={1000}
          data-tooltip-place="top"
          data-tooltip-variant="info"
        >
          {isLongTitle ? (
            <p className="cursor-pointer">
              {item.title.substring(0, 30) + "..."}
            </p>
          ) : (
            item.title
          )}
        </a>
        <Tooltip id={`tooltip-${item.id}`} className="!w-60 sm:!w-80" />
      </div>
    );
  };

  const getDescription = (item) => {
    const isLongDescription = item.description.length > 30;
    return (
      <div>
        <a
          data-tooltip-id={`tooltip-${item.id}`}
          data-tooltip-content={isLongDescription ? item.description : ""}
          data-tooltip-delay-show={1000}
          data-tooltip-place="top"
          data-tooltip-variant="info"
        >
          {isLongDescription ? (
            <p className="cursor-pointer">
              {item.description.substring(0, 30) + "..."}
            </p>
          ) : (
            item.description
          )}
        </a>
        <Tooltip id={`tooltip-${item.id}`} className="!w-60 sm:!w-80" />
      </div>
    );
  };

  // const ActionBtns = (item) => {
  //   return (
  //     <ActionBtnWrapper>
  //       {userType != Config.userType.MARKETING && (
  //         <Btn title="Edit Customer" onClick={() => edit(item)}>
  //           <MdModeEdit className="text-gray-700" />
  //         </Btn>)}
  //       <Btn title="View Detail" onClick={() => viewDetail(item)}>
  //         <IoMdEye className="text-gray-700" />
  //       </Btn>
  //     </ActionBtnWrapper>
  //   );
  // };

  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;
    } else if (status === 2) {
      return <Inprogress>Assigned</Inprogress>;
    } else {
      return <Resolve>Resolved</Resolve>;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Tenant Name",
          accessor: "tenantName",
        },
        {
          Header: "Title",
          accessor: "title",
        },
      ];
    }
    return [
      {
        Header: "Tenant Name",
        accessor: "tenantName",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: currentPage, pageSize: PaginationLimit },
    },
    useSortBy,
    usePagination
  );

  // useEffect(() => {
  //   setPageSize(PaginationLimit);
  // }, []);
  useEffect(() => {
    setCurrentPage(pageIndex); // Update current page on state change
  }, [pageIndex]);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${
                          column.isSortedDesc ? "-rotate-90" : "rotate-90"
                        } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>Description:</strong>{" "}
                          {data[rowIndex].description}
                        </li>
                        <li className="flex gap-2">
                          <strong>Status:</strong> {data[rowIndex].status}
                        </li>
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75"
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};
const MenuOptions = tw.div`absolute bottom-10 right-0 py-4 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative`;
// const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? "border-b" : "")}`;
const Tr = tw.tr`${(props) =>
  props.hasData
    ? "border-b"
    : ""} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
// const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Inprogress = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded-full`;
const Resolve = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded-full`;

export default Table;

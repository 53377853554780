//const mainDomain = "http://172.208.33.94/PROJECTS/Kipinn/cms/backend/public/";
const equaroDomain = "https://api-staging.eqaroguarantees.com/user/v1";
const mainDomain = "https://cms.kipinn.com/backend/public/";

const GetAccessToken = () => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  if (user) return user.token;

  return null;
};

const GetUserId = () => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  if (user) return user.userId;

  return null;
};

const Config = {
  AxiosConfig: {
    headers: {
      authorization: `${GetAccessToken()}`,
      id: GetUserId(),
    },
  },

  userType: {
    SUPERADMIN: 1,
    MARKETING: 3,
    OPERATION: 2,
  },
  eqaroDomain: equaroDomain,
  domain: mainDomain,
  apiUrl: mainDomain + "api",
  certificateUrl: mainDomain + "api/upload/CERTIFICATES/",
  docUrl: mainDomain + "api/upload/",
  sessionExpiredTime: 15, // in minutes
  idleTime: 15, // in mins

  roomTypeMapping: {
    1: "Single sharing",
    2: "Double sharing",
    3: "Triple sharing",
    4: "Three plus sharing",
  },

  roomStatus: {
    1: "Vacant",
    2: "Semi occupied",
    3: "Occupied",
    4: "Inactive",
  },

  dueType: {
    RENT: 1,
    SECURITY: 2,
    MAINTENANCE: 3,
    ELECTRICITY: 4,
    REGISTRATION: 5,
    TECH_CHARGES: 6,
    OTHER: 7,
    AGREMENT_CHARGE: 8,
    FOOD: 9,
    FINE: 10,
  },
};

export const dueTypes = [
  { value: 1, label: "Rent" },
  { value: 2, label: "Security" },
  { value: 3, label: "Maintenance" },
  { value: 4, label: "Electricity" },
  { value: 5, label: "Registration" },
  { value: 6, label: "Tech Charges" },
  { value: 7, label: "Other" },
  { value: 8, label: "Agreement Charge" },
  { value: 9, label: "Food" },
  { value: 10, label: "Fine" },
];

export const availableAmenities = [
  { label: "Balcony", value: "balcony" },
  { label: "Ac", value: "ac" },
  { label: "Tv", value: "tv" },
  { label: "Attached Bathroom", value: "attached bathroom" },
  { label: "Wi-Fi", value: "wi-fi" },
  { label: "Washing Machine", value: "washing machine" },
];

export const PaginationLimit = 10;

export const plans = [
  { value: 1, label: "Bronze" },
  { value: 2, label: "Silver" },
  { value: 3, label: "Gold" },
];

export const ForUs = (userId) => {
  if (userId === "e9ca6870-e80a-4df1-9b27-b6986fd6244a") {
    return true;
  }

  return false;
};

export const gTypeOfRoles = [
  { value: "1", label: "Registrar" },
  { value: "2", label: "Customers" },
  { value: "3", label: "Subadmins" },
  { value: "4", label: "Reports" },
];

export default Config;

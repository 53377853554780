import tw from "tailwind-styled-components";

export const Page = tw.section`py-24 md:pt-28 pb-14`;
export const Bg = tw.section`flex  items-center py-24 md:pt-28 pb-14`
// export const BoxContainer = tw.div`Container md:rounded-md md:border md:shadow-md md:p-8 `;
export const BoxContainer = tw.div`Container md:mt-4 md:p-4`;
export const BoxTitle = tw.h1`text-xl  text-gray-700 font-medium inline-flex space-x-2 items-center   cursor-pointer `;
export const BoxSubTitle = tw.h3`mt-1 ml-1 sm:mt-2 lg:mt-3   text-gray-500 text-sm lg:text-base `;
export const ErrorText = tw.p`my-20 text-red-600 text-sm text-center`;
export const Button = tw.button`
 text-white bg-primary-color transition hover:bg-sky-700  flex items-center !ml-auto space-x-1 justify-center w-40   h-10 text-xs  whitespace-nowrap rounded px-6
`;
export const PrevBtn = tw.div`w-8  h-8 rounded-full cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed  text-sm  border border-gray-300 text-gray-300 hover:text-gray-700 hover:border-gray-700 flex justify-center items-center  `;

export const NextBtn = tw.div`w-8  h-8 rounded-full cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed text-sm  border border-gray-300 text-gray-300 hover:text-gray-700 hover:border-gray-700  flex justify-center items-center  `;

export const PaginationWrapper = tw.div`flex mt-5 justify-end gap-3 items-center text-sm text-gray-400 mr-2 mb-2`;
export const Underline = tw.div`w-12 h-0.5 bg-gray-700 mt-1.5 rounded-full `;

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import tw from "tailwind-styled-components";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import NotFoundModel from "../../Components/NotFoundModel";
import EditOccupiedRoomsModel from "../../Components/OccupiedRooms/EditOccupiedRooms";
import Table from "../../Components/OccupiedRooms/Table";
import ViewDetailModel from "../../Components/OccupiedRooms/ViewDetail";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Config from "../../Config";
import Images from "../../Images";
// import Kyc from "../../Components/OccupiedRooms/Kyc";
import moment from "moment";

const OccupiedRooms = () => {
  const { propId, roomId } = useParams();
  const location = useLocation();
  const { name } = location.state || { name: "Unknown" };
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("mobile");
  const [editUser, setEditUser] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  // const [openKYCModel, setOpenKYCModel] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("propId", propId);
    body.append("roomId", roomId);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    return await axios.post(`${Config.apiUrl}/getroomoccupancies`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getOccupiedRoomsSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getOccupiedRoomsError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getOccupiedRoomsMutate,
    error,
    data,
    isRefetching,
  } = useMutation(fetchFunction, {
    onSuccess: getOccupiedRoomsSuccess,
    onError: getOccupiedRoomsError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getOccupiedRoomsMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    getOccupiedRoomsMutate();
  };

  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  //------- Edit Single Entry -------
  const EditOccupiedRoomsFunction = async (values) => {
    const body = new FormData();
    body.append("id", values.id);
    body.append("tenantId", values.tenantId);
    body.append("status", values.status);
    body.append("kycStatus", values.kycstatus);
    body.append("security", values.security);
    body.append("rent", values.rent);
    if (values.moveOutDate) {
      body.append(
        "moveOutDate",
        moment(values.moveOutDate).format("YYYY-MM-DD")
      );
    }
    return await axios.post(`${Config.apiUrl}/editOccupiedRooms`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getOccupiedRoomsMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editOccupiedRoomsMutate } =
    useMutation(EditOccupiedRoomsFunction, {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    });

  const getReportSuccess = (res) => {
    if (res?.statusText == "OK") {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(res.data);
      link.download = "exported_report.csv";

      link.click();
      toast.success(res?.data?.msg || "Report downloaded successfully");
    } else if (res.statusText == "NOK" || res.data.data == false) {
      toast.error(res?.data?.msg || "Error exporting report");
    }
  };

  const getReportError = (res) => {
    toast.error(error?.res?.data?.msg || "Error exporting report");
  };

  const { isLoading: isReportLoading, mutate: getReportMutate } = useMutation({
    onSuccess: getReportSuccess,
    onError: getReportError,
  });
  const handleButtonClick = () => {
    getReportMutate();
  };
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Occupied Room")}</Title>
              <Underline />
            </div>
            <Uploader t={t} />
          </div>
          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              ChangeHandler={ChangeHandler}
              filterValue={filterValue}
              filter={filter}
              user={user}
              handleButtonClick={handleButtonClick}
              isReportLoading={isReportLoading}
              t={t}
            />
          </div>

          {editUser && (
            <EditOccupiedRoomsModel
              editOccupiedRoomsMutate={editOccupiedRoomsMutate}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {viewDetail && (
            <ViewDetailModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}
          {/* {openKYCModel && (
            <Kyc
            setOpenKYCModel={setOpenKYCModel}
              selectedData={selectedData}
            />
          )} */}
          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={data?.data?.data || []}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditUser={setEditUser}
                setViewDetail={setViewDetail}
                userType={user.userType}
                // setOpenKYCModel={setOpenKYCModel}
              />
              {(error || data?.data?.msg === "No data found") && !isLoading && (
                <NotFoundModel />
              )}
            </TableWrapper>
          )}
          {(isLoading || isRefetching) && <Loading />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  filter,
  setFilter,
  setFilterValue,
  filterValue,
  ChangeHandler,
  t,
}) => {
  const today = moment().format("YYYY-MM-DD");
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <div className="flex gap-2 items-end sm:text-base text-xs flex-wrap">
            <div className="flex items-end gap-2">
              <InputGroup className="max-w-[130px]">
                <Label htmlFor="startDate">{t("Start_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="startDate"
                    id="startDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup className="max-w-[130px]">
                <Label htmlFor="endDate">{t("End_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="endDate"
                    id="endDate"
                    class="ml-1"
                    max={today}
                  />
                </FieldWrapper>
              </InputGroup>
            </div>
            <ApplyBtn disabled={InitialLoading} type="submit">
              {t("Date_filter")}
            </ApplyBtn>
          </div>

          <div className="flex gap-4 items-end">
            {filter !== "status" && filter !== "paystatus" ? ( // Render input field if filter is not "status" or "paystatus"
              <InputGroup className="max-w-[200px]">
                <Label htmlFor="filterVal">{t("")}</Label>
                <Field
                  placeholder="Search..."
                  type="text"
                  name="filterVal"
                  id="filterVal"
                  class="ml-1"
                  value={filterValue}
                  onChange={ChangeHandler}
                  className="border border-slate-300 rounded-md px-4 focus:ring-1 focus:ring-primary-color focus:border-primary-color py-2"
                />
              </InputGroup>
            ) : filter === "status" ? ( // Render dropdown for "Status"
              <InputGroup
                className="min-w-[120px]"
                style={{ flexBasis: "content" }}
              >
                <Label htmlFor="filter">{t("Select")}</Label>
                <Select
                  autoComplete="off"
                  className="px-2 bg-transparent text-sm border-none"
                  style={{ height: "40px" }}
                  value={filterValue}
                  onChange={ChangeHandler}
                >
                  <MenuItem value="" disabled>
                    {t("Select Status")}
                  </MenuItem>
                  <MenuItem value="1">{t("Pending")}</MenuItem>
                  <MenuItem value="2">{t("Requested")}</MenuItem>
                  <MenuItem value="3">{t("Occupied")}</MenuItem>
                  <MenuItem value="4">{t("Moving Out")}</MenuItem>
                  <MenuItem value="5">{t("Reserved")}</MenuItem>
                </Select>
              </InputGroup>
            ) : null}
            <InputGroup
              className="min-w-[120px]"
              style={{ flexBasis: "content" }}
            >
              <Label htmlFor="filter">{t("Search By")}</Label>

              <Select
                autoComplete="off"
                className="w-24 px-2 bg-transparent text-sm border-none"
                style={{ height: "40px" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setFilterValue("");
                }}
              >
                <MenuItem value="mobile">Phone</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </Select>
            </InputGroup>
          </div>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = ({ setOpenUploadModel, t }) => {
  return <UploadWrapper></UploadWrapper>;
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-primary-color hover:bg-hover-color  py-2.5 text-sm`;

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;
export default OccupiedRooms;

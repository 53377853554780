import React from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputGroup, Label, SubmitBtn } from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const AddCollege = ({ setAddColleges, loading, mutate }) => {
  const InitialValue = {
    name: "",
    city: "Delhi",
  };
  const formValidation = Yup.object().shape({
    name: Yup.string().required("Please Select Name"),
    city: Yup.string().required("Please Select city"),
  });

  const postData = (values) => {
    mutate(values);
  };
  const collegeForm = useFormik({
    initialValues: InitialValue,
    validationSchema: formValidation,
    onSubmit: postData,
  });

  const cities = ["Delhi", "Noida", "Gurugram","Ghaziyabad","Pune"];

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setAddColleges}>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <Title>Add College</Title>
          <form onSubmit={collegeForm.handleSubmit}>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Name of the college"
                    onChange={collegeForm.handleChange}
                    value={collegeForm.values.name}
                  />
                  {collegeForm.touched.name && collegeForm.errors.name ? (
                    <p className="text-rose-600 text-sm">
                      {collegeForm.errors.name}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="city">City</Label>
                <FieldWrapper>
                  <Select
                    name="city"
                    onChange={collegeForm.handleChange}
                    value={collegeForm.values.city}
                    className="w-full pl-2"
                  >
                    {cities.map((city, index) => (
                      <MenuItem key={index} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                  {collegeForm.touched.city && collegeForm.errors.city ? (
                    <p className="text-rose-600 text-sm">
                      {collegeForm.errors.city}
                    </p>
                  ) : null}
                </FieldWrapper>
              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Submit</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};

const Wrapper = tw.div`px-1  space-y-4`;
const FieldWrapper = tw.div`w-full`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Input = tw.input`truncate border border-gray-300 border-m rounded pl-2 focus:ring-1 focus:ring-sky-600 focus:border-sky-600 focus:outline-none duration-200`;

export default AddCollege;

{
  /* <Input
  type="text"
  name="city"
  placeholder="Enter City"
  onChange={collegeForm.handleChange}
  value={collegeForm.values.city}
/> */
}

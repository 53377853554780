import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useMutation } from "react-query";
import axios from "axios";
import tw from "tailwind-styled-components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../Config";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";

const typeOptions = [
  { value: "Co-Living", label: "Co-Living" },
  { value: "PG Operator", label: "PG Operator" },
];

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Za-z]/, "Password must contain at least one letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .required("Password is required"),
  // type: Yup.object().required("Type is required"),
});

const RegistrationPage = () => {
  const navigate = useNavigate();
  const InitialValues = {
    email: "",
    password: "",
    type: "Co-living/PG Operator",
    userType: "landlord",
  };
  const RegisterFunction = async (values) => {
    return await axios.post(`${Config.equaroDomain}/auth/register`, values);
  };

  const RegisterSuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(data?.data?.msg || "Registration successful!");
      navigate("/equaroLogin");
    }
  };

  const RegisterError = (error) => {
    toast.error(
      error?.response?.data?.msg || "An error occurred during registration."
    );
  };

  const { isLoading: isRegisterLoading, mutate: onMutateRegister } =
    useMutation(RegisterFunction, {
      onSuccess: RegisterSuccess,
      onError: RegisterError,
    });

  const handleSubmit = (values, { setSubmitting }) => {
    const formattedValues = {
      ...values,
      type: "Co-living/PG Operator",
      userType: "landlord",
    };

    onMutateRegister(formattedValues);
    setSubmitting(false);
  };

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <FormContainer>
            <HeadingWrapper>
              <Heading>Equaro Registration</Heading>
              <Subheading>
                Create an account to start managing your tasks effortlessly.
              </Subheading>
            </HeadingWrapper>
            <FormWrapper>
              <Formik
                initialValues={InitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, setFieldValue, errors, touched }) => (
                  <Form className="space-y-6">
                    <div>
                      <Label htmlFor="email">Email</Label>
                      <Field name="email" type="email" as={Input} />
                      <ErrorMessage name="email" component={ErrorText} />
                    </div>

                    <div>
                      <Label htmlFor="password">Password</Label>
                      <Field name="password" type="password" as={Input} />
                      <ErrorMessage name="password" component={ErrorText} />
                    </div>

                    <div>
                      <Button
                        type="submit"
                        disabled={isSubmitting || isRegisterLoading}
                      >
                        {isRegisterLoading ? "Registering..." : "Register"}
                      </Button>
                      {/* <p className="text-right mt-2 text-sm text-gray-700">
                        Already have an account?{" "}
                        <button
                          onClick={() => navigate("/equaroLogin")}
                          className="text-blue-500 underline"
                        >
                          Login
                        </button>
                      </p> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </FormWrapper>
          </FormContainer>
        </BoxContainer>
      </Bg>
    </>
  );
};

const FormContainer = tw.div`mt-4 sm:mx-auto sm:w-full sm:max-w-md`;
const HeadingWrapper = tw.div`text-center mb-5`;
const Heading = tw.h1`text-3xl font-extrabold text-gray-900`;
const Subheading = tw.p`text-xs sm:text-sm text-gray-600 mt-2`;
const FormWrapper = tw.div`bg-white py-6 sm:py-8 px-4 shadow rounded-lg sm:px-10`;
const Label = tw.label`block text-sm font-medium text-gray-700 mb-1`;
const Input = tw.input`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`;
const Button = tw.button`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50`;
const ErrorText = tw.div`text-red-500 text-sm mt-1`;
export default RegistrationPage;

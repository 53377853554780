import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  // console.log("ashihs" + JSON.stringify(selectedData));

  const ledgerType = (type) => {
    if (type === 1) {
      return <span>Rent</span>;
    } else if (type === 2) {
      return <span>Security</span>;
    } else if (type === 3) {
      return <span>Maintenance</span>;
    } else if (type === 4) {
      return <span>Electricity</span>;
    } else if (type === 5) {
      return <span>Registration</span>;
    } else if (type === 6) {
      return <span>Tech_Changes</span>;
    } else if (type === 7) {
      return <span>Others</span>;
    } else if (type === 8) {
      return <span>Agreement Charges</span>;
    } else if (type === 9) {
      return <span>Food</span>;
    } else if (type === 10) {
      return <span>Fine</span>;
    }
  };

  const getOccupation = (occupation) => {
    if (occupation == 1) {
      return "Student";
    } else {
      return "Professional";
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title>View Details</Title>

        <Column>
          <FirstColumn>
            <SubHeading>Property Name</SubHeading>
            <Paragraph>{selectedData?.propName}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Client Name</SubHeading>
            <Paragraph>{selectedData?.clientName}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />

        <Column>
          <FirstColumn>
            <SubHeading>Type</SubHeading>
            <Paragraph>{ledgerType(selectedData?.type)}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Id</SubHeading>
            <Paragraph>{selectedData?.id}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />

        <Column>
          <FirstColumn>
            <SubHeading>Property Id</SubHeading>
            <Paragraph>{selectedData?.propId}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Client Id</SubHeading>
            <Paragraph>{selectedData?.clientId}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />

        <Column>
          <FirstColumn>
            <SubHeading>Amount</SubHeading>
            <Paragraph>{selectedData?.amount}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Balance</SubHeading>
            <Paragraph>{selectedData?.balance}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />

        <Column>
          <FirstColumn>
            <SubHeading>Updated At</SubHeading>
            <Paragraph>
              {moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A")}
            </Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Created At</SubHeading>
            <Paragraph>
              {moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A")}
            </Paragraph>
          </FirstColumn>
        </Column>
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const FirstColumn = tw.div``;
const Column = tw.div`grid grid-cols-2 my-3 gap-2`;
const SubHeading = tw.h2`text-xs text-blue-500 font-[600] mb-1`;
const Paragraph = tw.p`text-sm text-gray-900 font-semibold`;

export default ViewDetailModel;

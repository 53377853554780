import React, { useState } from "react";
import RoomContainsOptions from "./RoomContainsOptions";
import RoomDoesNotContainsOptions from "./RoomDoesNotContainsOptions";

//Add new Data to flatData
const FlatRoom = ({
  roomOwnerMutate,
  roomEntryLoading,
  setRoomUser,
  selectedData,
  data,
  roomOptions,
}) => {
  return (
    <>
      {selectedData?.roomOptionId ? (
        <RoomContainsOptions
          setRoomUser={setRoomUser}
          selectedData={selectedData}
          roomEntryLoading={roomEntryLoading}
          clientId={data.clientId}
        />
      ) : (
        <RoomDoesNotContainsOptions
          setRoomUser={setRoomUser}
          selectedData={selectedData}
          roomEntryLoading={roomEntryLoading}
          apiData={data}
          roomOptions={roomOptions}
          roomOwnerMutate={roomOwnerMutate}
          roomOwnerLoading={roomEntryLoading}
        />
      )}
    </>
  );
};

// const Wrapper = tw.div`mt-3 mb-3`;
// const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl text-gray-700 font-bold text-left`;
// const BtnWrapper = tw.div`w-full space-x-10 mt-8`;

export default FlatRoom;

import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import { IoBedSharp } from "react-icons/io5";

const ViewDetail = ({ setViewDetail, selectedData }) => {
  const getStatus = (status) => {
    if (status === 1) {
      return <Unverified>Unverified</Unverified>;
    } else if (status === 2) {
      return <Vacant>Vacant</Vacant>;
    } else if (status === 3) {
      return <Linked>Linked</Linked>;
    } else if (status === 4) {
      return <Requested>Requested</Requested>;
    } else if (status === 5) {
      return <Occupied>Occupied</Occupied>;
    } else if (status === 6) {
      return <MovingOut>Moving Out</MovingOut>;
    } else {
      return <Reserved>Reserved</Reserved>;
    }
  };

  return (
    <Model width="w-11/12 max-w-lg" setOpenModel={setViewDetail}>
      <Wrapper>
        <Title>
          <MdViewHeadline className="mt-1" />
          View Beds
        </Title>
        {selectedData?.beds?.map((bed, index) => (
          <div
            key={index}
            className="flex items-center py-2 border-b border-gray-100"
          >
            <div className="">
              <IoBedSharp className="text-green-500" />
            </div>
            <div className="flex-1 ml-4">
              <div className="text-xs text-gray-500">{`ID: ${
                bed.bedId || "N/A"
              }`}</div>
              <div className="font-semibold">{bed.tenantName || "N/A"}</div>
              <div className="text-yellow-500 text-xs">
                {bed.number || "N/A"}
              </div>
            </div>
            <div className="flex-shrink-0 ml-4">
              <div className="text-sm font-medium">
                {getStatus(bed.tenantStatus) || "N/A"}
              </div>
            </div>
            {index < selectedData.beds.length - 1 && (
              <hr className="my-2 border-gray-200" />
            )}
          </div>
        ))}
      </Wrapper>
    </Model>
  );
};

// Styled components
const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Unverified = tw.div`bg-rose-100 text-rose-600 font-bold text-xs grid place-items-center rounded-md px-2 py-1 w-24 h-7`;
const Vacant = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const Linked = tw.div`bg-teal-100 text-emerald-600 text-xs grid font-bold place-items-center rounded-lg px-2 py-1 w-24 h-7`;
const Requested = tw.div`bg-lime-100 text-lime-700 text-xs border-lime-300 border w-24 h-7 flex items-center justify-center rounded`;
const Occupied = tw.div`bg-red-100 text-red-700 text-xs border-red-300 border w-24 h-7 flex items-center justify-center rounded`;
const MovingOut = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const Reserved = tw.div`bg-orange-100 text-orange-700 text-xs border-orange-300 border w-24 h-7 flex items-center justify-center rounded`;

export default ViewDetail;

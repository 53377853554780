import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import moment from "moment";
import Config, { PaginationLimit } from "../../Config";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoMdEye, IoMdMore } from "react-icons/io";
import {
  FaHome,
  FaShieldAlt,
  FaTools,
  FaBolt,
  FaFileContract,
  FaLaptop,
  FaEllipsisH,
  FaHandshake,
  FaUtensils,
  FaExclamationCircle,
} from "react-icons/fa";
const DuesTable = ({
  ApiData,
  setSelectedData,
  setViewDetail,
  setEditDues,
  userType,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [previousId, setPreviousId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActionMenu, setOpenActionMenu] = useState(null);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const edit = (item) => {
    setEditDues(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };
  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
    setOpenActionMenu(null);
  };

  const ActionBtns = (item) => {
    const openMenu = (id) => {
      setPreviousId(openActionMenu);
      setOpenActionMenu((prevId) => {
        return prevId === id ? null : id;
      });
    };

    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            {userType != Config.userType.MARKETING && (
              <MenuItem title="Edit Tenant" onClick={() => edit(item)}>
                <MdModeEdit className="text-gray-700 mr-2" /> Edit Dues
              </MenuItem>
            )}
            <MenuItem title="View Detail" onClick={() => viewDetail(item)}>
              <IoMdEye className="text-gray-700 mr-2" /> View Detail
            </MenuItem>
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      // console.log("hii", ApiData);
      const tempData = ApiData.map((item) => ({
        getPropName: getPropName(item),
        type: getDuesType(item),
        dueDate: dueDate(item),
        amountBalance: amountBalance(item),
        rentStartEnd: rentStartEnd(item),
        action: ActionBtns(item),
      }));
      setData(tempData);
    }
  }, [ApiData, openActionMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#menuOptions") &&
        !event.target.closest(".action-btn")
      ) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const duesType = (type) => {
    // if (type === 1) {
    //   return (
    //     <span>
    //       <FaHome className="inline mr-2 -mt-1 text-gray-500" />
    //       Rent
    //     </span>
    //   );
    // } else if (type === 2) {
    //   return (
    //     <span>
    //       <FaShieldAlt className="inline mr-2 -mt-1 text-gray-500" />
    //       Security
    //     </span>
    //   );
    // } else if (type === 3) {
    //   return (
    //     <span>
    //       <FaTools className="inline mr-2 text-gray-500 -mt-1" />
    //       Maintenance
    //     </span>
    //   );
    // } else if (type === 4) {
    //   return (
    //     <span>
    //       <FaBolt className="inline mr-2 text-gray-500 -mt-1" />
    //       Electricity
    //     </span>
    //   );
    // } else if (type === 5) {
    //   return (
    //     <span>
    //       <FaFileContract className="inline mr-2 text-gray-500 -mt-1" />
    //       Registration
    //     </span>
    //   );
    // } else if (type === 6) {
    //   return (
    //     <span>
    //       <FaLaptop className="inline mr-2 text-gray-500 -mt-1" />
    //       Tech Changes
    //     </span>
    //   );
    // } else if (type === 7) {
    //   return (
    //     <span>
    //       <FaEllipsisH className="inline mr-2 text-gray-500 -mt-1" />
    //       Others
    //     </span>
    //   );
    // } else if (type === 8) {
    //   return (
    //     <span>
    //       <FaHandshake className="inline mr-2 text-gray-500 -mt-1" />
    //       Agreement Charges
    //     </span>
    //   );
    // } else if (type === 9) {
    //   return (
    //     <span>
    //       <FaUtensils className="inline mr-2 text-gray-500 -mt-1" />
    //       Food
    //     </span>
    //   );
    // } else if (type === 10) {
    //   return (
    //     <span>
    //       <FaExclamationCircle className="inline mr-2 text-gray-500 -mt-1" />
    //       Fine
    //     </span>
    //   );
    // } else {
    //   return null;
    // }
    const types = {
          1: { icon: <FaHome />, label: "Rent", color: "bg-blue-100 text-blue-500" },
          2: { icon: <FaShieldAlt />, label: "Security", color: "bg-green-100 text-green-500" },
          3: { icon: <FaTools />, label: "Maintenance", color: "bg-yellow-100 text-yellow-500" },
          4: { icon: <FaBolt />, label: "Electricity", color: "bg-orange-100 text-orange-500" },
          5: { icon: <FaFileContract />, label: "Registration", color: "bg-purple-100 text-purple-500" },
          6: { icon: <FaLaptop />, label: "Tech Changes", color: "bg-pink-100 text-pink-500" },
          7: { icon: <FaEllipsisH />, label: "Others", color: "bg-gray-100 text-gray-500" },
          8: { icon: <FaHandshake />, label: "Agreement Charges", color: "bg-teal-100 text-teal-500" },
          9: { icon: <FaUtensils />, label: "Food", color: "bg-red-100 text-red-500" },
          10: { icon: <FaExclamationCircle />, label: "Fine", color: "bg-indigo-100 text-indigo-500" },
        };
    
        if (!types[type]) return null;
        const { icon, label, color } = types[type];
        return (
          <span className="flex items-center space-x-3">
            <span
              className={`flex items-center justify-center w-9 h-9 rounded-full ${color} shadow-md`}
            >
              {icon}
            </span>
            <span className="text-zinc-900 font-semibold ">{label}</span>
          </span>
        );
  };

  const getDuesType = (item) => {
    const getItemType = duesType(item.type);
    return (
      // <span className="bg-yellow-100 px-3 py-1 rounded-full text-gray-600 font-medium inline-flex gap-2 items-center">
      // {getItemType}
      // </span>
      <div className="flex gap-1 font-semibold">
        <span>{getItemType}</span>
      </div>
    );
  };

  const dueDate = (item) => {
    return (
      <div className="flex flex-col">
        <span>
          {item.dueDate ? moment(item.dueDate).format("DD-MM-YYYY") : "---"}
        </span>
      </div>
    );
  };

  const getPropName = (item) => {
    return (
      <div className="flex flex-col">
        <span className="text-zinc-900 font-medium">{item.propName} </span>
      </div>
    );
  };

  const amountBalance = (item) => {
    return (
      <div className="flex gap-4 ">
        <div className="flex flex-col ">
          <span>{item.amount}</span>
          <span className="text-gray-400 font-medium text-xs">
            Total Amount
          </span>
        </div>
        <div className="flex flex-col">
          <span>{item.balance}</span>
          <span className="text-gray-400 font-medium text-xs">
            Total Balance
          </span>
        </div>
      </div>
    );
  };

  const rentStartEnd = (item) => {
    return (
      <div className="flex flex-col">
        <span>
          R.Start :{" "}
          {item.rentStartDate
            ? moment(item.rentStartDate).format("DD-MM-YYYY")
            : "---"}
        </span>
        <span>
          R.End :{" "}
          {item.rentEndDate
            ? moment(item.rentEndDate).format("DD-MM-YYYY")
            : "---"}
        </span>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Dues",
          accessor: "type",
        },
        {
          Header: "Dues Date",
          accessor: "dueDate",
        },
      ];
    }
    return [
      {
        Header: "Dues",
        accessor: "type",
      },
      {
        Header: "Dues Date",
        accessor: "dueDate",
      },
      {
        Header: "Amount/Balance",
        accessor: "amountBalance",
      },
      // {
      //   Header: "Rent Start/End",
      //   accessor: "rentStartEnd",
      // },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: currentPage, pageSize: PaginationLimit },
    },
    useSortBy,
    usePagination
  );

  // useEffect(() => {
  //   setPageSize(PaginationLimit);
  // }, []);
  useEffect(() => {
    setCurrentPage(pageIndex); // Update current page on state change
  }, [pageIndex]);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${
                          column.isSortedDesc ? "-rotate-90" : "rotate-90"
                        } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>Due Date</strong> {data[rowIndex].dueDate}
                        </li>
                        <li>
                          <strong>Amount/Balance</strong>{" "}
                          {data[rowIndex].amountBalance}
                        </li>
                        <li className="flex gap-2">
                          <strong>Type</strong> {data[rowIndex].type}
                        </li>
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75"
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const MenuOptions = tw.div`absolute bottom-10 right-0 py-2 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative`;
// const  ActionBtnWrapper = tw.div`
//   flex flex-wrap items-center w-[110px] mb-1 gap-y-1 gap-x-1.5 mt-20 md:mt-0
// `;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? "border-b" : "")}`;
const Tr = tw.tr`${(props) =>
  props.hasData
    ? "border-b"
    : ""} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;

export default DuesTable;

// import React from "react";
// import tw from "tailwind-styled-components";
// import { MdViewHeadline } from "react-icons/md";
// import Model from "../Model";
// import moment from "moment";

// const ViewDetailModel = ({ setViewDetail, selectedData }) => {
//   const getGender = (selectedData) => {
//     if (selectedData.gender == 1) {
//       return <>Male</>;
//     } else if (selectedData.gender == 2) {
//       return <>Female</>;
//     }
//   };
//   const getStatus = (status) => {
//     if (status === 1) {
//       return <Pending>Pending</Pending>;
//     } else if (status === 2) {
//       return <Resolve>Resolved</Resolve>;
//     } else {
//       return <Inprogress>Inprogress</Inprogress>;
//     }
//   };
//   return (
//     <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
//       <Wrapper>
//         <Title>
//           View Details
//         </Title>
//         <div className="flex ">
//            {/* <Status>{getStatus(selectedData?.id) || "N/A"}</Status> */}
//           <Status className="ml-3">{getStatus(selectedData?.status) || "N/A"}</Status>
//         </div>
//         <SingleItem
//           name="Tenant Name"
//           value={selectedData?.tenantName || "N/A"}
//         />
//         <SingleItem
//           name="Client Name"
//           value={selectedData?.clientName || "N/A"}
//         />
//         <SingleItem
//           name="Property Name"
//           value={selectedData?.propertyName || "N/A"}
//         />
//         <SingleItem name="Title" value={selectedData?.title || "N/A"} />
//         <SingleItem name="Room Number" value={selectedData?.roomId || "N/A"} />
//         <SingleItem name="Floor" value={selectedData?.floor || "N/A"} />
//         <SingleItem
//           name="Description"
//           value={selectedData?.description || "N/A"}
//         />
//         <SingleItem
//           name="Assigned To"
//           value={selectedData?.assignedTo || "N/A"}
//         />
//         <SingleItem
//           name="Created At"
//           value={
//             moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A") ||
//             "N/A"
//           }
//         />
//         <SingleItem
//           name="Updated At"
//           value={
//             moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A") ||
//             "N/A"
//           }
//         />
//       </Wrapper>
//     </Model>
//   );
// };

// const SingleItem = ({ name, value }) => (
//   <div className="flex items-center py-2 border-b border-gray-100">
//     <Name>{name}</Name> <p>:</p>
//     {<Value>{value}</Value>}
//   </div>
// );
// const Status = tw.div``;
// const Wrapper = tw.div`px-4`;
// const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
// const Name = tw.p`text-sm text-gray-500  w-32`;
// const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
// const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
// const Inprogress = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
// const Resolve = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;

// export default ViewDetailModel;

import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";
import { Tooltip } from "react-tooltip";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const getGender = (selectedData) => {
    if (selectedData.gender == 1) {
      return <>Male</>;
    } else if (selectedData.gender == 2) {
      return <>Female</>;
    }
  };
  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;
    } else if (status === 2) {
      return <Completed>Resolved</Completed>;
    } else {
      return <Active>Inprogress</Active>;
    }
  };

  const openImage = (url) => {
    let a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.click();
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title>View Details</Title>

        <div className="flex my-4">
          {/* <Status>{getStatus(selectedData?.id) || "N/A"}</Status> */}
          <Status className="">
            {getStatus(selectedData?.status) || "N/A"}
          </Status>
        </div>

        <Column>
          <FirstColumn>
            <SubHeading>Tenant Name</SubHeading>
            <Paragraph> {selectedData?.tenantName || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Client Name</SubHeading>
            <Paragraph> {selectedData?.clientName || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Property Name</SubHeading>
            <Paragraph> {selectedData?.propertyName || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Title</SubHeading>
            <Paragraph> {selectedData?.title || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Room Number</SubHeading>
            <Paragraph> {selectedData?.roomId || "N/A"}</Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Floor</SubHeading>
            <Paragraph> {selectedData?.floor || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Description</SubHeading>
            <Paragraph
              data-tooltip-id="description-tooltip"
              data-tooltip-content={
                selectedData?.description?.length > 20
                  ? selectedData.description
                  : ""
              }
              data-tooltip-delay-show={1000}
              data-tooltip-place="top"
              data-tooltip-variant="info"
            >
              {selectedData?.description?.length > 20
                ? selectedData.description.substring(0, 20) + "..."
                : selectedData?.description || "N/A"}
            </Paragraph>
            <Tooltip id="description-tooltip" className="!w-60 sm:!w-80" />
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Assigned To</SubHeading>
            <Paragraph> {selectedData?.assignedTo || "N/A"}</Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Created At</SubHeading>
            <Paragraph>
              {" "}
              {moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A") ||
                "N/A"}
            </Paragraph>
          </FirstColumn>
          <FirstColumn>
            <SubHeading>Updated At</SubHeading>
            <Paragraph>
              {" "}
              {moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A") ||
                "N/A"}
            </Paragraph>
          </FirstColumn>
        </Column>
        <hr />
        <Column>
          <FirstColumn>
            <SubHeading>Image</SubHeading>
            <Paragraph>
              {selectedData?.img ? (
                <p className="border-rounded cursor-pointer">
                  <img
                    src={selectedData?.img}
                    width={50}
                    height={50}
                    onClick={() => openImage(selectedData?.img)}
                  />
                </p>
              ) : (
                "N/A"
              )}
            </Paragraph>
          </FirstColumn>
        </Column>
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);
const Status = tw.div``;
const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Completed = tw.div`bg-purple-600 text-white text-xs inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Active = tw.div`bg-emerald-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const Pending = tw.div`bg-yellow-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Inprogress = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const Resolve = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const FirstColumn = tw.div``;
const Column = tw.div`grid grid-cols-2 my-3 gap-2`;
const SubHeading = tw.h2`text-xs text-blue-500 font-[600] mb-1`;
const Paragraph = tw.p`text-sm text-gray-900 font-semibold`;
export default ViewDetailModel;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import axios from "axios";
import tw from "tailwind-styled-components";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Navbar from "../../Components/Navbar";
import Images from "../../Images";
import { t } from "i18next";
import Select from "react-select";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SubmitBtn } from "../../Components/Styles/InputStyles";

const propertyCategoryOptions = [
  { value: "Co-Living", label: "Co-Living" },
  { value: "Family Home", label: "Family Home" },
  { value: "Paying Guest House", label: "Paying Guest House" },
  { value: "Serviced Apartment", label: "Serviced Apartment" },
];

const propertyTypeOptions = [
  { value: "Apartment", label: "Apartment" },
  { value: "Villa", label: "Villa" },
  { value: "Studio", label: "Studio" },
  { value: "Builder floor", label: "Builder floor" },
  { value: "Bungalow", label: "Bungalow" },
  { value: "Independent House", label: "Independent House" },
  { value: "Bunk Bed", label: "Bunk Bed" },
  { value: "Private Room", label: "Private Room" },
  { value: "Double Shared", label: "Double Shared" },
  { value: "Triple Shared", label: "Triple Shared" },
];

const amenitiesOptions = [
  { value: "Wi-Fi", label: "Wi-Fi" },
  { value: "Geyser", label: "Geyser" },
  { value: "Balcony", label: "Balcony" },
  { value: "TV", label: "TV" },
  { value: "Attached Bathroom", label: "Attached Bathroom" },
  { value: "AC", label: "AC" },
  { value: "Cooler", label: "Cooler" },
  { value: "Washing Machine", label: "Washing Machine" },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  No_of_Bedrooms: Yup.number().required("Required").positive().integer(),
  ownerFullName: Yup.string().required("Required"),
  servantRoom: Yup.boolean().required("Required"),
  studyRoom: Yup.boolean().required("Required"),
  noOfBathrooms: Yup.number().required("Required").positive().integer(),
  expectedRent: Yup.number().required("Required").positive(),
  expectedBondValue: Yup.number().required("Required").positive(),
  expectedAdvanceRent: Yup.number().required("Required").positive(),
  propertyAge: Yup.number().required("Required").positive(),
  landlordId: Yup.string().required("Required"),
  propertyAddress: Yup.object().shape({
    addressType: Yup.string().required("Required"),
    street: Yup.string().required("Required"),
    locality: Yup.string().required("Required"),
    house: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    pin: Yup.string()
      .required("Required")
      .matches(/^\d{6}$/, "Must be exactly 6 digits"),
  }),

  productId: Yup.string().required("Required"),
});

const PropertyForm = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const InitialValues = {
    name: "",
    propertyType: null,
    No_of_Bedrooms: "",
    ownerFullName: "",
    propertyCategory: null,
    servantRoom: false,
    studyRoom: false,
    noOfBathrooms: "",
    // propertyImages: [],
    expectedRent: "",
    expectedBondValue: "",
    expectedAdvanceRent: "",
    propertyAge: "",
    landlordId: "",
    propertyAddress: {
      addressType: "current",
      street: "",
      locality: "",
      house: "",
      city: "",
      state: "",
      pin: "",
    },
    amenities: [],
    productId: "",
  };
  const addPropertyEqaro = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("No_of_Bedrooms", values.No_of_Bedrooms);
    body.append("ownerFullName", values.ownerFullName);
    body.append("noOfBathrooms", values.noOfBathrooms);
    body.append("expectedRent", values.expectedRent);
    body.append("expectedBondValue", values.expectedBondValue);
    body.append("expectedAdvanceRent", values.expectedAdvanceRent);
    body.append("propertyAge", values.propertyAge);
    body.append("landlordId", values.landlordId);
    body.append("productId", values.productId);
    body.append("studyRoom", values.studyRoom);
    body.append("servantRoom", values.servantRoom);
    body.append("addressType", "current");
    body.append("street", values.propertyAddress.street);
    body.append("locality", values.propertyAddress.locality);
    body.append("house", values.propertyAddress.house);
    body.append("city", values.propertyAddress.city);
    body.append("state", values.propertyAddress.state);
    body.append("pin", values.propertyAddress.pin);
    body.append("amenities", values.amenities);
    body.append("propertyCategory", values.propertyCategory);
    body.append("propertyType", values.propertyType);
    // body.append("propertyImages", values.propertyImages);
    return await axios.post(`${Config.eqaroDomain}/property`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const getPropertySuccess = (data) => {
    if (data?.data?.status === "OK") {
      toast.success(
        data?.data?.msg || "Property successfully linked with eqaro."
      );
    }
  };
  const getTenantsError = (res) => {
    console.error("Error:", res);
  };

  const { isLoading: isPropertyLoading, mutate: addPropertyMutate } =
    useMutation(addPropertyEqaro, {
      onSuccess: getPropertySuccess,
      onError: getTenantsError,
    });
  const SubmitHandler = (values, { setSubmitting }) => {
    if (!values.amenities || values.amenities.length === 0) {
      toast.error("Amenities are required.");
      setSubmitting(false);
      return;
    }

    if (!values.propertyCategory || !values.propertyCategory.value) {
      toast.error("Property Category is required.");
      setSubmitting(false);
      return;
    }

    if (!values.propertyType || !values.propertyType.value) {
      toast.error("Property Type is required.");
      setSubmitting(false);
      return;
    }

    const formattedValues = {
      ...values,
      amenities: values.amenities.map((amenity) => amenity.value),
      propertyCategory: values.propertyCategory?.value,
      propertyType: values.propertyType?.value,
    };
    console.log(formattedValues);
    addPropertyMutate(formattedValues);
    setSubmitting(false);
  };

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Property Form")}</Title>
              <Underline />
            </div>
          </div>
          <div className=" mx-auto mt-8 p-6 bg-white rounded-lg shadow-xl">
            <Formik
              initialValues={InitialValues}
              validationSchema={validationSchema}
              onSubmit={SubmitHandler}
            >
              {({
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                errors,
                touched,
                values,
              }) => (
                <Form className="space-y-6 ">
                  <TwoCol>
                    <div>
                      <Label htmlFor="name">Name</Label>
                      <Field name="name" as={Input} type="text" />
                      <ErrorMessage name="name" component={ErrorText} />
                    </div>

                    <div>
                      <Label htmlFor="propertyType">Property Type</Label>
                      <Select
                        className="mt-1"
                        name="propertyType"
                        options={propertyTypeOptions}
                        onChange={(selectedOption) =>
                          setFieldValue("propertyType", selectedOption)
                        }
                        value={values.propertyType}
                        classNamePrefix="select"
                      />
                      {errors.propertyType && touched.propertyType && (
                        <ErrorText>{errors.propertyType}</ErrorText>
                      )}
                    </div>
                  </TwoCol>
                  <TwoCol>
                    <div>
                      <Label htmlFor="ownerFullName">Owner Full Name</Label>
                      <Field name="ownerFullName" as={Input} type="text" />
                      <ErrorMessage
                        name="ownerFullName"
                        component={ErrorText}
                      />
                    </div>
                    <div>
                      <Label htmlFor="propertyCategory">
                        Property Category
                      </Label>
                      <Select
                        className="mt-1"
                        name="propertyCategory"
                        options={propertyCategoryOptions}
                        onChange={(selectedOption) =>
                          setFieldValue("propertyCategory", selectedOption)
                        }
                        value={values.propertyCategory}
                        classNamePrefix="select"
                      />
                      {errors.propertyCategory && touched.propertyCategory && (
                        <ErrorText>{errors.propertyCategory}</ErrorText>
                      )}
                    </div>

                    <div>
                      <Label>Servant Room</Label>
                      <RadioGroup>
                        <label>
                          <Field type="radio" name="servantRoom" value="true" />
                          Yes
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="servantRoom"
                            value="false"
                          />
                          No
                        </label>
                      </RadioGroup>
                      <ErrorMessage name="servantRoom" component={ErrorText} />
                    </div>

                    <div>
                      <Label>Study Room</Label>
                      <RadioGroup>
                        <label>
                          <Field type="radio" name="studyRoom" value="true" />
                          Yes
                        </label>
                        <label>
                          <Field type="radio" name="studyRoom" value="false" />
                          No
                        </label>
                      </RadioGroup>
                      <ErrorMessage name="studyRoom" component={ErrorText} />
                    </div>
                  </TwoCol>
                  <TwoCol>
                    <div>
                      <Label htmlFor="noOfBathrooms">Number of Bathrooms</Label>
                      <Field name="noOfBathrooms" as={Input} type="number" />
                      <ErrorMessage
                        name="noOfBathrooms"
                        component={ErrorText}
                      />
                    </div>
                    <div>
                      <Label htmlFor="No_of_Bedrooms">Number of Bedrooms</Label>
                      <Field name="No_of_Bedrooms" as={Input} type="number" />
                      <ErrorMessage
                        name="No_of_Bedrooms"
                        component={ErrorText}
                      />
                    </div>
                  </TwoCol>
                  <ThreeCol>
                    {/* <div>
                      <Label htmlFor="propertyImages">
                        Property Images (Optional)
                      </Label>
                      <Field
                        name="propertyImages"
                        as={Input}
                        type="file"
                        multiple
                      />
                    </div> */}

                    <div>
                      <Label htmlFor="expectedRent">Expected Rent</Label>
                      <Field name="expectedRent" as={Input} type="number" />
                      <ErrorMessage name="expectedRent" component={ErrorText} />
                    </div>

                    <div>
                      <Label htmlFor="expectedBondValue">
                        Expected Bond Value
                      </Label>
                      <Field
                        name="expectedBondValue"
                        as={Input}
                        type="number"
                      />
                      <ErrorMessage
                        name="expectedBondValue"
                        component={ErrorText}
                      />
                    </div>

                    <div>
                      <Label htmlFor="expectedAdvanceRent">
                        Expected Advance Rent
                      </Label>
                      <Field
                        name="expectedAdvanceRent"
                        as={Input}
                        type="number"
                      />
                      <ErrorMessage
                        name="expectedAdvanceRent"
                        component={ErrorText}
                      />
                    </div>
                  </ThreeCol>
                  <TwoCol>
                    <div>
                      <Label htmlFor="propertyAge">Property Age</Label>
                      <Field name="propertyAge" as={Input} type="number" />
                      <ErrorMessage name="propertyAge" component={ErrorText} />
                    </div>

                    <div>
                      <Label htmlFor="landlordId">Landlord ID</Label>
                      <Field name="landlordId" as={Input} type="text" />
                      <ErrorMessage name="landlordId" component={ErrorText} />
                    </div>
                  </TwoCol>
                  <div>
                    <h3 className="text-lg font-medium mb-2">
                      Property Address
                    </h3>
                    <div className="space-y-4">
                      <ThreeCol>
                        <div>
                          <Label htmlFor="propertyAddress.street">Street</Label>
                          <Field
                            name="propertyAddress.street"
                            as={Input}
                            type="text"
                          />
                          <ErrorMessage
                            name="propertyAddress.street"
                            component={ErrorText}
                          />
                        </div>
                        <div>
                          <Label htmlFor="propertyAddress.locality">
                            Locality
                          </Label>
                          <Field
                            name="propertyAddress.locality"
                            as={Input}
                            type="text"
                          />
                          <ErrorMessage
                            name="propertyAddress.locality"
                            component={ErrorText}
                          />
                        </div>
                        <div>
                          <Label htmlFor="propertyAddress.house">House</Label>
                          <Field
                            name="propertyAddress.house"
                            as={Input}
                            type="text"
                          />
                          <ErrorMessage
                            name="propertyAddress.house"
                            component={ErrorText}
                          />
                        </div>
                        <div>
                          <Label htmlFor="propertyAddress.city">City</Label>
                          <Field
                            name="propertyAddress.city"
                            as={Input}
                            type="text"
                          />
                          <ErrorMessage
                            name="propertyAddress.city"
                            component={ErrorText}
                          />
                        </div>
                        <div>
                          <Label htmlFor="propertyAddress.state">State</Label>
                          <Field
                            name="propertyAddress.state"
                            as={Input}
                            type="text"
                          />
                          <ErrorMessage
                            name="propertyAddress.state"
                            component={ErrorText}
                          />
                        </div>
                        <div>
                          <Label htmlFor="propertyAddress.pin">PIN Code</Label>
                          <Field
                            name="propertyAddress.pin"
                            as={Input}
                            type="text"
                          />
                          <ErrorMessage
                            name="propertyAddress.pin"
                            component={ErrorText}
                          />
                        </div>
                      </ThreeCol>
                    </div>
                  </div>
                  <TwoCol>
                    <div>
                      <Label htmlFor="amenities">Amenities</Label>
                      <Select
                        isMulti
                        name="amenities"
                        options={amenitiesOptions}
                        onChange={(selectedOptions) =>
                          setFieldValue("amenities", selectedOptions)
                        }
                        value={values.amenities}
                        className="mt-1"
                        classNamePrefix="select"
                      />
                      {errors.amenities && touched.amenities && (
                        <ErrorText>{errors.amenities}</ErrorText>
                      )}
                    </div>

                    <div>
                      <Label htmlFor="productId">Product ID</Label>
                      <Field name="productId" as={Input} type="text" />
                      <ErrorMessage name="productId" component={ErrorText} />
                    </div>
                  </TwoCol>
                  <BtnWrapper>
                    <SubmitBtn
                      type="submit"
                      disabled={isSubmitting || isPropertyLoading}
                    >
                      {isPropertyLoading ? "Submitting..." : "Submit"}
                    </SubmitBtn>
                  </BtnWrapper>
                </Form>
              )}
            </Formik>
          </div>
        </BoxContainer>
      </Bg>
    </>
  );
};

const ThreeCol = tw.div`grid md:grid-cols-3 grid-col-1 gap-4`;
const TwoCol = tw.div`grid md:grid-cols-2 grid-col-1 gap-4`;
const Input = tw.input`mt-1 pl-2 block w-full rounded border border-m border-gray-400 focus:border-sky-600 focus:ring-1 focus:ring-sky-600 focus:ring-opacity-50 focus:outline-none duration-200`;
const Label = tw.label`block text-sm font-medium text-gray-700`;
const Button = tw.button`mt-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`;
const ErrorText = tw.div` text-red-500 text-sm mt-1`;
const RadioGroup = tw.div`mt-2 space-x-10 flex items-center`;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap font-medium`;
const BtnWrapper = tw.div`w-full flex items-center justify-end space-x-10`;
export default PropertyForm;

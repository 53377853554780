// import React from "react";
// import tw from "tailwind-styled-components";
// import { MdViewHeadline } from "react-icons/md";
// import Model from "../Model";
// import moment from "moment";

// const ViewDetailModel = ({ setViewDetail, selectedData }) => {

//   const getStatus = (status) => {
//     if (status === 1) {
//       return <Pending>Pending</Pending>;
//     } else if (status === 2) {
//       return <Completed>Completed</Completed>;
//     } else if (status === 3) {
//       return <Active>Active</Active>;
//     } else if (status === 4) {
//       return <Inactive>Inactive</Inactive>;
//     } else {
//       return 'Invalid Status';
//     }
//   };

//   return (
//     <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
//       <Wrapper>
//         <Title><MdViewHeadline className="mt-1" />View Details</Title>
//         <SingleItem name={"Get Id"} value={selectedData?.gId || "N/A"} />
//         <SingleItem name={"Name"} value={selectedData?.name || "N/A"} />
//         <SingleItem name={"Owner Name"} value={selectedData?.ownerName || "N/A"} />
//         <SingleItem name={"Phone Number"} value={selectedData?.ownerMobile || "N/A"} />
//         <SingleItem name={"Address"} value={selectedData?.address || "N/A"} />
//         <SingleItem name={"Street Address"} value={selectedData?.streetAddress || "N/A"} />
//         <SingleItem name="Fine" value={selectedData?.fine || "N/A"} />
//         <SingleItem name="Security" value={selectedData?.security || "N/A"} />
//         <SingleItem name="Grace Period" value={selectedData?.gracePeriod || "N/A"} />
//         <SingleItem name="Agreement Period" value={selectedData?.agreementPeriod || "N/A"} />
//         <SingleItem name="Notice Period" value={selectedData?.noticePeriod || "N/A"} />
//         <SingleItem name="Lock-In Period" value={selectedData?.lockInPeriod || "N/A"} />
//         <SingleItem name="Status" value={getStatus(selectedData?.status) || "N/A"} />
//         <SingleItem name={"Type"} value={selectedData?.type || "N/A"} />
//         <SingleItem name={"Floor Count"} value={selectedData?.floorCount || "N/A"} />
//         <SingleItem name="Is Ground Included" value={selectedData?.isGroundIncluded || "N/A"} />
//         <SingleItem name="Rental Cycle" value={selectedData?.rentalCycle || "N/A"} />
//         <SingleItem name="Bank ID" value={selectedData?.bankId || "N/A"} />
//         <SingleItem name="Police Verification Enabled" value={selectedData?.isPoliceVerificationEnabled || "N/A"} />
//         <SingleItem name="ID Verification Enabled" value={selectedData?.isIdVerificationEnabled || "N/A"} />
//         <SingleItem name={"Created At"} value={moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A") || "N/A"} />
//         <SingleItem name={"Updated At"} value={moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A") || "N/A"} />
//       </Wrapper>
//     </Model>
//   );
// };

// const SingleItem = ({ name, value }) => (
//   <div className="flex items-center py-2 border-b border-gray-100">
//     <Name>{name}</Name> <p>:</p>
//     <Value>
//       {(name === "Security" || name === "Fine" || name === "Maintenance Fee") && (value != 'N/A') && <span className="mr-1">₹</span>}
//       {value}
//       {(name === "Grace Period" || name === "Agreement Period" || name === "Notice Period" || name === "Lock-In Period") && <span className="ml-1">months</span>}
//     </Value>
//   </div>
// );

// const Wrapper = tw.div`px-4`;
// const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
// const Name = tw.p`text-sm text-gray-500  w-32`;
// const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

// const Active = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
// const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
// const Completed = tw.div`bg-lime-100 text-lime-700 text-xs border-lime-300 border w-24 h-7 flex items-center justify-center rounded`;
// const Inactive = tw.div`bg-red-100 text-red-700 text-xs border-red-300 border w-24 h-7 flex items-center justify-center rounded`;
// export default ViewDetailModel;

import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const [detailType, setDetailType] = useState("1");

  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;
    } else if (status === 2) {
      return <Completed>Completed</Completed>;
    } else if (status === 3) {
      return <Active>Active</Active>;
    } else {
      return <Inactive>Inactive</Inactive>;
    }
  };

  const getPolice = (isPoliceVerificationEnabled) => {
    if (isPoliceVerificationEnabled === 1) {
      return <p>Yes</p>;
    } else {
      return <p>No</p>;
    }
  };

  const getId = (isIdVerificationEnabled) => {
    if (isIdVerificationEnabled === 1) {
      return <p>Yes</p>;
    } else {
      return <p>No</p>;
    }
  };

  const getGround = (isGroundIncluded) => {
    if (isGroundIncluded === 1) {
      return <p>Yes</p>;
    } else {
      return <p>No</p>;
    }
  };
  const getRent = (isRentAgreementEnabled) => {
    if (isRentAgreementEnabled === 1) {
      return <p>Yes</p>;
    } else {
      return <p>No</p>;
    }
  };

  const getProperty = (gId) => {
    if (gId) {
      return <PropertyId>#{gId}</PropertyId>;
    } else {
      return <Inactive>N/A</Inactive>;
    }
  };

  const getAgreement = (rentAgreementPath) => {
    if (rentAgreementPath) {
      return <a
        href={selectedData.rentAgreementPath}
        target="_blank"
        rel="noopener noreferrer"
        className="underline text-primary-color text-sm"
      >
        Click Me
      </a>;
    }
  };

  const getSign = (signaturePath) => {
    if (signaturePath) {
      return <a
        href={selectedData.signaturePath}
        target="_blank"
        rel="noopener noreferrer"
        className="underline text-primary-color text-sm"
      >
        Click Me
      </a>;
    }
  };

  const getType = (type) => {
    if (type == 1) {
      return <p>PG</p>;
    } else {
      return <p>Flat</p>;
    }
  };

  const getPrefrence = (tenantPrefrence) => {
    if (tenantPrefrence == 1) {
      return <p>Boy</p>;
    } else if (tenantPrefrence == 2) {
      return <p>Girl</p>;
    } else {
      return <p>Both</p>;
    }
  };



  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setViewDetail}>
      <Wrapper>

        <Title>View Details</Title>
        <div className="flex my-4">
          <Status>{getProperty(selectedData?.gId) || "N/A"}</Status>
          <Status className="ml-3">{getStatus(selectedData?.status) || "N/A"}</Status>
        </div>

        <SubHeading>Name</SubHeading>
        <Paragraph>{selectedData?.name || "N/A"}</Paragraph>

        <Column>
          {/* <Heading>#{selectedData?.gId}</Heading> */}

          {/* <FirstColumn>
            <SubHeading>Name</SubHeading>
            <Paragraph>{selectedData?.name || "N/A"}</Paragraph>
          </FirstColumn> */}

          <FirstColumn>
            <SubHeading>Created At</SubHeading>
            <Paragraph>{moment(selectedData?.createdAt).format("LL") || "N/A"}</Paragraph>
          </FirstColumn>
          <SecondColumn>
            <SubHeading>Updated At</SubHeading>
            <Paragraph>{moment(selectedData?.updatedAt).format("LL") || "N/A"}</Paragraph>
          </SecondColumn>
        </Column>

        <ButtonGroup>
          <TemplateButton
            isActive={detailType === "1"}
            onClick={() => setDetailType("1")}
          >
            Basic Details
          </TemplateButton>
          <TemplateButton
            ml
            isActive={detailType === "2"}
            onClick={() => setDetailType("2")}
          >
            Agreement Details
          </TemplateButton>
          <TemplateButton
            ml
            isActive={detailType === "3"}
            onClick={() => setDetailType("3")}
          >
            Rent Details
          </TemplateButton>
        </ButtonGroup>
        <Seperator />

        <div className="h-[37vh] overflow-y-auto">
          {detailType === "1" && (
            <>

              <SingleItem name={"Owner Name"} value={selectedData?.ownerName || "N/A"} />
              <SingleItem name={"Phone Number"} value={selectedData?.ownerMobile || "N/A"} />
              <SingleItem name={"Address"} value={selectedData?.address || "N/A"} />
              <SingleItem name={"Street Address"} value={selectedData?.streetAddress || "N/A"} />
              <SingleItem name={"Type"} value={getType(selectedData?.type || "N/A")} />
              <SingleItem name={"Prefrence"} value={getPrefrence(selectedData?.tenantPrefrence || "N/A")} />
              <SingleItem name={"Floor Count"} value={selectedData?.floorCount || "N/A"} />
              <SingleItem name="Bed" value={selectedData?.bedCount || 0} />
              <SingleItem name="Tenant" value={selectedData?.tenantCount || 0} />
              <SingleItem name="Is Ground Included" value={getGround(selectedData?.isGroundIncluded || "N/A")} />
              <SingleItem name="Owner Sign." value={getSign(selectedData?.signaturePath || "N/A")} />
            </>
          )}

          {detailType === "2" && (
            <>
              <SingleItem name="Grace Period" value={selectedData?.gracePeriod || "N/A"} />
              <SingleItem name="Agreement Period" value={selectedData?.agreementPeriod || "N/A"} />
              <SingleItem name="Notice Period" value={selectedData?.noticePeriod || "N/A"} />
              <SingleItem name="Lock-In Period" value={selectedData?.lockInPeriod || "N/A"} />
              <SingleItem name="Police Verification" value={getPolice(selectedData?.isPoliceVerificationEnabled || "N/A")} />
              <SingleItem name="ID Verification" value={getId(selectedData?.isIdVerificationEnabled || "N/A")} />
            </>
          )}

          {detailType === "3" && (
            <>
              <SingleItem name="Fine" value={selectedData?.fine || "0"} />
              <SingleItem name="Security" value={selectedData?.security || "N/A"} />
              <SingleItem name="Rental Cycle" value={selectedData?.rentalCycle || "N/A"} />
              <SingleItem name="Rent Agreement" value={getRent(selectedData?.isRentAgreementEnabled || "N/A")} />
              <SingleItem name="Agreement" value={getAgreement(selectedData?.rentAgreementPath || "N/A")} />
            </>
          )}
        </div>
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-1.5 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    <Value>
      {(name === "Security" || name === "Fine" || name === "Maintenance Fee") && value !== 'N/A' && <span className="mr-1">₹</span>}
      {value}
      {(name === "Agreement Period" || name === "Notice Period" || name === "Lock-In Period") && <span className="ml-1">months</span>}
      {(name === "Grace Period") && <span className="ml-1">days</span>}
    </Value>
  </div>
);

const Heading = tw.h1`text-2xl my-3 font-bold text-black text-left`;
const ButtonGroup = tw.div`flex items-center !gap-0 mt-6 `;
const TemplateButton = tw.button`
  pl-0 pr-2 font-medium text-sm relative ${(props) =>
    props.isActive ? "text-blue-500" : "text-gray-400"}
  ${(props) => (props.ml ? "ml-4" : "")}
  after:absolute after:top-7 after:left-0 after:w-full after:h-0.5
  ${(props) => (props.isActive ? "after:bg-blue-500" : "")}
`;
const Seperator = tw.div`w-full h-[0.07rem] bg-gray-200 my-2 `;
const Status = tw.div``;
const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl text-gray-700 font-bold text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-black font-medium w-80 px-6 `;
const PropertyId = tw.div`bg-cyan-600 text-white text-xs  inline-block py-1 max-w-fit px-3 flex items-center justify-center rounded-full`;
const Active = tw.div`bg-emerald-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Pending = tw.div`bg-yellow-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Completed = tw.div`bg-purple-600 text-white text-xs inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Inactive = tw.div`bg-rose-600 text-white text-xs  inline-block py-1 max-w-fit px-4 flex items-center justify-center rounded-full`;
const Column = tw.div`grid grid-cols-2 my-3 gap-2`;
const FirstColumn = tw.div``;
const SecondColumn = tw.div``;
const SubHeading = tw.h2`text-xs text-blue-500 font-[600] mb-1`;
const Paragraph = tw.p`text-sm text-gray-900 font-semibold`;
export default ViewDetailModel;
